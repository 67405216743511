import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { MoneyDisplay } from '@kelbongoo/shared-client-react-v2/dist/components/MoneyDisplay';
import Badge from 'react-bootstrap/Badge';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { BoutiqueOrder } from '../../../../models/BoutiqueOrder';

export const BoutiqueOrdersRow = ({ item }) => {

  let boutiqueorder = new BoutiqueOrder(item);

  console.log(boutiqueorder)

  // useEffect(() => {
  //   consumersFetch({consumer_id, consumer});
  // }, [])

  return (
    <tr key={boutiqueorder._id}>

      {/* Date de creation de la commande */}
      <td>
        <Link to={`/caisse/boutiqueorders/${boutiqueorder._id}`} >
          {formatDate(boutiqueorder.createdAt, 'HH:mm')}
        </Link>
      </td>

      {/* Consommateurice */}
      {/* <td>
        {consumer ? `${consumer.firstname}&nbsp;${consumer.lastname}` : 'Inconnu'}
      </td> */}

      {/* Nombre d'articles */}
      <td>
        {boutiqueorder.items.length + boutiqueorder.reduced.length}
      </td>

      {/* Nombre d'articles */}
      <td>

        {!boutiqueorder.paidAt ?
          <Badge variant='danger' className='mx-2'>
            <Icon name='exclamation-triangle' />
          </Badge>
          :
          <Badge variant='success' className='mx-2'>
            <Icon name='check' />
          </Badge>
        }

        <MoneyDisplay
          value={boutiqueorder.getAmountTotal()}
        />
      </td>

    </tr>
  )
}