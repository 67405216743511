import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";


export const unmarkArrived = ({
  globalorder_id,
  locale,
  createAlert,
  loadData,
    loadQueueData
}) => (order_id) => () => {

  const caller = wrapClientApiCall({
    url: 'globalorderlocales/unmark-order-arrived',
  })

  return caller({
    params: {
      globalorder_id,
      locale,
      order_id,
    },
  })
  .then(() => {
    loadData()
    loadQueueData()
  })
    .catch((err) => createAlert(err));
};