import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

import { actions } from '../reducers/temperatureRecorders';

export const getTemperatureRecorders = () => (dispatch, getState) => {

    const { locales: { currentLocale: locale } } = getState();

    const {
        fetchTemperatureRecordersRequest,
        fetchTemperatureRecordersSuccess,
        fetchTemperatureRecordersFailed
      } = actions;

    const caller = wrapClientApiCall({
        url: 'temperaturerecorders/fetch',
    });

    dispatch(fetchTemperatureRecordersRequest());

    return caller({
        params: {
            context_key: 'locale',
            context_value: locale
        }
    })
        .then(({ items }) => {
            dispatch(fetchTemperatureRecordersSuccess(items));
        })
        .catch((error) => dispatch(fetchTemperatureRecordersFailed(error.message || error.name)));
};