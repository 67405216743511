import {
    ventilateAmountTotalByTva,
} from '@kelbongoo/shared-methods/boutiqueorders/collection';

export const getAmountTotal = (order) => {

    const amountTotalVentilated = ventilateAmountTotalByTva({
        items: order.items,
        reduced: order.reduced
    })

    return Object.keys(amountTotalVentilated)
        .reduce((acc, key) => acc += Math.ceil(key !== 'total' ? amountTotalVentilated[key] : 0), 0)

    // let amountTotal = 0;
    // if (order.items && order.items.length > 0) {
    //     order.items.map(item => { amountTotal += item.consumer_price})
    // }
    // if(order.reduced && order.reduced.length > 0) order.reduced.map(item => {amountTotal += item.consumer_price})
    // return amountTotal;
}