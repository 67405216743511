import { useState } from 'react';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ValidationButton } from '@kelbongoo/shared-client-react-v2/dist/components/ValidationButton';

export const ModalBody = ({
    closeModal, // inherited
    isReduced,
    quantity,
    updateItem
}) => {

    const [selectedQuantity, setSelectedQuantity] = useState(quantity)
    const [selectedPercentage, setSelectedPercentage] = useState(100)

    const allowValidation = isReduced || (selectedPercentage && selectedQuantity);

    const onValidation = () => {
        const output =
            isReduced ? { unreduce: true }
                : {
                    quantity: selectedQuantity,
                    percentage: selectedPercentage,
                    reason: 1
                };
        closeModal();
        updateItem(output);
    };

    const validationButtonText = isReduced ? 'De-remiser le produit' : 'Valider';

    const possibleQuantities = [...Array(quantity + 1).keys()];

    const handleQuantityChange = (event) => {
        let val = parseInt(event.currentTarget.value);
        setSelectedQuantity(val);
    }

    const handlePercentageChange = (event) => {
        let val = parseInt(event.currentTarget.value);
        setSelectedPercentage(val);
    }

    return (
        <>
            {!isReduced &&
                <>
                    <Row className='mb-3'>
                        <Col xs='12'>
                            <Form.Group controlId="quantity">
                                <Form.Label>Quantit&eacute; &agrave; remiser</Form.Label>
                                <Form.Control as="select" onChange={handleQuantityChange} custom>
                                    <option disabled>Choisir une quantit&eacute;</option>
                                    {possibleQuantities.map(possibleQuantity => (
                                        <option
                                            value={possibleQuantity}
                                            selected={selectedQuantity === possibleQuantity}>
                                            {possibleQuantity}
                                        </option>
                                    ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>

                    {selectedQuantity &&
                        <>
                            <Row className='mb-5'>
                                <Col xs='12'>
                                    <Form.Group controlId="pourcentage">
                                        <Form.Label>Pourcentage</Form.Label>
                                        <Form.Control as="select" onChange={handlePercentageChange} custom>
                                            <option disabled>Choisir une remise</option>
                                            <option value='10' selected={selectedPercentage === 10}>10%</option>
                                            <option value='20' selected={selectedPercentage === 20}>20%</option>
                                            <option value='30' selected={selectedPercentage === 30}>30%</option>
                                            <option value='40' selected={selectedPercentage === 40}>40%</option>
                                            <option value='50' selected={selectedPercentage === 50}>50%</option>
                                            <option value='60' selected={selectedPercentage === 60}>60%</option>
                                            <option value='70' selected={selectedPercentage === 70}>70%</option>
                                            <option value='80' selected={selectedPercentage === 80}>80%</option>
                                            <option value='90' selected={selectedPercentage === 90}>90%</option>
                                            <option value='100' selected={selectedPercentage === 100}>100%</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    }
                </>
            }

            {allowValidation &&
                <Row className='mb-2'>
                    <Col xs='12'>
                        <ValidationButton
                            onValidation={onValidation}
                            buttonClasses='btn-primary btn-block'
                            buttonText={validationButtonText}
                        />
                    </Col>
                </Row>
            }

        </>)
}