import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const markBacsDelivered = ({
    createAlert,
    loadBacs,
    bacs,
    setBacs
}) => {

    const savedBacs = bacs;

    const formattedBacs = bacs.filter(bac => !!bac.pickedUpAt)
        .map(bac => ({
            _id: bac._id,
            waiting: typeof bac.place === 'undefined'
        }));

    if (!(formattedBacs && formattedBacs.length > 0)) {
        loadBacs();
        return;
    }

    const caller = wrapClientApiCall({
        url: 'orders/mark-bacs-delivered',
    });

    return caller({ params: { bacs: formattedBacs } })
        .then(({ bacs }) => {
            setBacs(savedBacs.filter(savedBac => !bacs.some(bac => bac._id === savedBac._id) ))
        })
        .catch((err) => {
            createAlert(err)
        })
        .finally(loadBacs());
};