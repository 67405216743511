import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'places',
  initialState: {
    places: []
  },
  reducers: {
    fetchPlacesRequest(state){
      state.loading = true;
      state.error = undefined;
    },
    fetchPlacesSuccess(state, action){
      state.places = action.payload;
      state.loading = false;
    },
    fetchPlacesFailed(state, action){
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export { reducer, actions };