export const tableColumns = [
  {
    title: 'Consommateur.ice',
  },
  {
    title: 'Manquants',
    classes: 'vertical-center',
    width: '40%',
  },
  {
    title: 'Total précommande',
    classes: 'vertical-center',
    width: '20%',
  },
  {
    title: 'Réglée',
    classes: 'text-center vertical-center',
  }
]