import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const addLocalePayment = ({
    globalorder_id,
    locale,
    createAlert,
    refreshData, // should call format order dans setcurrentorder
    order_id,
}) => ({
    added,
    added_reduced,
    payments,
}) => {

        if (payments.length === 0) return createAlert(new Error('no payments'));

        const payment = payments.pop(); // take the last payment in the array ie the most recent

        const caller = wrapClientApiCall({
            url: 'globalorderlocales/add-locale-payment',
        });

        return caller({
            params: {
                globalorder_id,
                locale,
                order_id,
                payment,
                added,
                added_reduced,
                userId: '2W8X2SaMhytesy4ne', // WARN
            }
        })
            .then(({ order, boutiqueorder }) => { refreshData() })
            .catch(err => {
                createAlert(err)
            })
    };