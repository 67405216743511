import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container';

import { Datatable } from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';

import { ErrorBoundary } from '../../ui/ErrorBoundary';
import { boutiqueordersFetch, boutiquedaysFetch } from './actions';
import { tableColumns, getAmountTotal as getAmountTotalBase } from './helpers';
import { BoutiqueOrdersRow } from './BoutiqueOrdersRow';


export const BoutiqueOrdersBase = () => {

  const dispatch = useDispatch();

  const { currentLocale: locale } = useSelector(state => state.locales);
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const { boutiqueday } = useSelector(state => state.boutiqueday);
  const { init } = useSelector(state => state.session);
  const { gopps } = useSelector(state => state.gopps);

  const defaultQuery = {};

  const [boutiqueorders, setBoutiqueorders] = useState([]);
  const [count, setCount] = useState([]);
  const [page, setPage] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentBoutiqueOrdersQuery, setCurrentBoutiqueOrdersQuery] = useState(defaultQuery);
  const [loading, setLoading] = useState(true);

  const createAlert = arg => dispatch(createAlertBase(arg))

  // how to get that from boutiqueorder class?
  const getAmountTotal = (order) => () => { return getAmountTotalBase(order) }

  const updateQuery = update => {
    setCurrentBoutiqueOrdersQuery({
      ...currentBoutiqueOrdersQuery,
      ...(update || {})
    })
    setPage(1)
  }

  const loadData = () => {
    boutiqueordersFetch({
      createAlert,
      currentBoutiqueOrdersQuery,
      docsPerPage,
      gopps,
      locale,
      page,
      setCount,
      setBoutiqueorders,
      setLoading,
      boutiqueday_id: boutiqueday && boutiqueday._id,
      getAmountTotal
    })
  }


  useEffect(loadData, [currentBoutiqueOrdersQuery, page, globalorder_id, gopps, locale, docsPerPage, init, boutiqueday])

  return (

    <Container fluid>

      <Datatable
        columns={tableColumns}
        count={count}
        currentPage={page}
        DatatableRow={BoutiqueOrdersRow}
        docsPerPage={docsPerPage}
        items={boutiqueorders}
        loading={loading || init}
        showPagination={true}
        tableName={`accueil`}
        updateDocsPerPage={setDocsPerPage}
        updatePage={setPage}
        updateQuery={updateQuery}
        useSelector={useSelector}
        dispatch={dispatch}
      />

    </Container>
  )
}

export const BoutiqueOrders = () => (
  <ErrorBoundary>
    <EnsureGlobalOrderWrapper>
      <BoutiqueOrdersBase />
    </EnsureGlobalOrderWrapper>
  </ErrorBoundary>
)