import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const producersGetAllNames = (createAlert) => ({ search }) => {

    const caller = wrapClientApiCall({
        url: 'producers/get-all-names',
    });

    return caller({
        params: {
            name: search
        }
    })
        .then((producers) => producers.map(p => ({
            text: p.name,
            display: p.name,
            value: p._id
        })))
        .catch(err => {
            createAlert(err)
        })
};