import { TEMPERATURE_DANGER_TRESHOLD, TEMPERATURE_WARNING_TRESHOLD } from './constants.js'

export const temperatureClass = (temperature) => {
  if (temperature > TEMPERATURE_DANGER_TRESHOLD) {
    return 'bg-danger';
  }
  else if (temperature > TEMPERATURE_WARNING_TRESHOLD) {
    return 'bg-warning';
  }
  else {
    return 'bg-success';
  }
};