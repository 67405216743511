import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';

import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';

import { getRoleName, getDropdownButtonVariant, getRoleFromPath } from './helpers';
import selectors from '../../../selectors';

export const HeaderItemsRight = () => {

  const isMobile = !!window.cordova;

  const location = useLocation();

  const { loggedIn } = useSelector(state => state.currentUser);
  const { currentGlobalOrder } = useSelector(state => state.globalorderlocales)
  const { ok: temperaturesOk } = useSelector(state => state.temperatureLevel)
  const { list: temperatureRecorders } = useSelector(state => state.temperatureRecorders);
  const localeDocument = selectors.getCurrentLocaleDocument();

  if (!loggedIn || !currentGlobalOrder) {
    return <div />
  }

  const currentRole = getRoleFromPath(location.pathname);
  const roleName = getRoleName(location.pathname);

  const showTemperatureAlert = isMobile && localeDocument && localeDocument.partner && !!temperatureRecorders && temperatureRecorders.length > 0;

  return (
    <>

      <Nav.Item className='mx-2' style={{ display: 'inline-block' }}>
        {showTemperatureAlert ?
          <Button as={Link} to="/temperatures" variant={temperaturesOk ? 'success' : 'danger'}>
            <Icon name={temperaturesOk ? 'temperature-low' : 'temperature-high'} />
          </Button>
          : ''}
      </Nav.Item>

      <Nav.Item style={{ display: 'inline-block' }}>

        <DropdownButton
          menuAlign='right'
          variant={getDropdownButtonVariant(location.pathname)}
          title={roleName}
          style={{ zIndex: 100, marginRight: '10px' }}
        >

          <Dropdown.Item as={Link} to="/" style={{ fontSize: '1.2em', padding: '15px 17px' }} className={currentRole === 'home' ? 'active' : ''}>
            <Icon name='home' textRight />
            &nbsp; Home
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/accueil" style={{ fontSize: '1.2em', padding: '15px 17px' }} className={currentRole === 'accueil' ? 'active' : ''}>
            <Icon name='sign-in-alt' group='fas' textRight />
            &nbsp; Accueil
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/couloir" style={{ fontSize: '1.2em', padding: '15px 17px' }} className={currentRole === 'couloir' ? 'active' : ''}>
            <Icon name='dolly' textRight />
            &nbsp; Couloirs
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/table" style={{ fontSize: '1.2em', padding: '15px 17px' }} className={currentRole === 'table' ? 'active' : ''}>
            <Icon name='people-carry' textRight />
            &nbsp; Tables
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/caisse" style={{ fontSize: '1.2em', padding: '15px 17px' }} className={currentRole === 'caisse' ? 'active' : ''}>
            <Icon name='cash-register' group='fas' textRight />
            &nbsp; Caisse
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/orders-nr" style={{ fontSize: '1.2em', padding: '15px 17px' }} className={currentRole === 'orders-nr' ? 'active' : ''}>
            <Icon name='backward' textRight />
            &nbsp; Commandes NR
          </Dropdown.Item>

          <Dropdown.Item as={Link} to="/tasks" style={{ fontSize: '1.2em', padding: '15px 17px' }} className={currentRole === 'tasks' ? 'active' : ''}>
            <Icon name='tasks' textRight />
            &nbsp; T&acirc;ches
          </Dropdown.Item>

          {isMobile &&
            <Dropdown.Item as={Link} to="/temperatures" style={{ fontSize: '1.2em', padding: '15px 17px' }} className={currentRole === 'temperatures' ? 'active' : ''}>
              <Icon name='thermometer-half' textRight />
              &nbsp; Temp&eacute;ratures
            </Dropdown.Item>
          }

        </DropdownButton>

      </Nav.Item>

    </>
  )
}