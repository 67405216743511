import {
    formatProducerProductIds,
    updateItemQuantity as updateItemQuantityBase,
    updateItemReduction as updateItemReductionBase,
    toggleCheckedItem as toggleCheckedItemBase,
} from '.';

export const formatItems = ({
    currentOrder,
    checkedItems,
    setCheckedItems,
    items,
    setItems,
    reduced,
    setReduced,
    removed,
    setRemoved,
}) => {

    let { producerproductIds } = formatProducerProductIds({ items, reduced, removed });

    const updateItemQuantity = updateItemQuantityBase({ items, setItems, reduced, setReduced, removed, setRemoved })
    const updateItemReduction = updateItemReductionBase({ items, setItems, reduced, setReduced })
    const toggleCheckedItem = toggleCheckedItemBase({ checkedItems, setCheckedItems })

    return producerproductIds.map(producerproduct_id => {

        const pp = currentOrder.producerproducts.find(pp => pp._id === producerproduct_id)

        const item = items.find(i => i.producerproduct_id === pp._id);
        const itemQuantity = (item && item.quantity) || 0;

        const removedItem = removed.find(i => i.producerproduct_id === pp._id);
        const removedQuantity = (removedItem && removedItem.quantity) || 0;

        const reducedItem = reduced.find(i => i.producerproduct_id === pp._id);
        const reducedQuantity = (reducedItem && reducedItem.quantity) || 0;

        const currentQuantity = itemQuantity + reducedQuantity;
        const expectedQuantity = currentQuantity + removedQuantity;

        const completed = !!checkedItems.find(item => item === pp._id)

        return currentOrder && {
            quantity: currentQuantity,
            expectedQuantity,
            product_name: pp.product_name,
            producer_name: pp.producer_name,
            unit_display: pp.unit_display,
            producerproduct_id: pp._id,
            reduced: reducedQuantity,
            percentage: reducedItem && reducedItem.percentage,
            onToggleChecked: toggleCheckedItem(producerproduct_id),
            updateQuantity: updateItemQuantity({producerproduct_id, currentQuantity, expectedQuantity}),
            updateReduction: updateItemReduction(producerproduct_id),
            validated: !!currentOrder.nrValidatedAt,
            disabled: !!currentOrder.nrValidatedAt || checkedItems.indexOf(producerproduct_id) > -1,
            abandonned: currentOrder.isAbandonned(),
            refundable: !!pp.refundable,
            paid: !!currentOrder.paidAt,
            completed 
        }
    })
}