import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const fetchConsumers = ({
    createAlert
}) => (search) => {


    const caller = wrapClientApiCall({
        url: 'consumers/fetch',
    });

    const params = {
        email: search,
        lastname: search
    }

    const options = {
        fields: { firstname: 1, lastname: 1, email: 1 },
        limit: 8,
        sort: { lastname: 1, firstname: 1 }
    }

    return caller({ params, options })
        .then(({ items }) => items)
        .catch(err => createAlert(err));
};