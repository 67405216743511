import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { formatOrder } from '../helpers';

export const fetchDetail = ({
  boutiqueorder_id,
  setLoading,
  createAlert,
  setCurrentOrder,
  setItems, 
  setReduced,
  setConsumerId
}) => {

  setLoading(true)

  const caller = wrapClientApiCall({
    url: 'boutiqueorders/fetch-detail',
  });

  return caller({
    params: {
      boutiqueorder_id,
    }
  })
    .then(({ boutiqueorder }) => {
      setCurrentOrder(formatOrder(boutiqueorder))
      setItems(boutiqueorder.items)
      setReduced(boutiqueorder.reduced)
      setConsumerId(boutiqueorder.consumer_id)
    })
    .catch(err => { createAlert(err) })
    .finally(
      setLoading(false)
    )
};