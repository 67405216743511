export const getAvailablePlaces = (locale, places, orders) => {
  
    let occupiedPlaces = orders.reduce((a,o) => {
      if(typeof o.table !== 'undefined' && typeof o.place !== 'undefined'){
        a.push({ table: o.table, place: o.place })
      }
      return a
    }, [])

    let tables = locale.tables.filter(
      t => !!t.active
    )

    let activePlaces = places.map(p => ({
      table: p.table,
      tableName: typeof tables[p.table] !== 'undefined' ? tables[p.table].name : p.table,
      place: p.place, 
      alias: p.alias,
    }))

    return activePlaces.filter(p => {
      return !occupiedPlaces.find(o => o.table === p.table && o.place === p.place)
    })
  }