import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const markOrderDeparted = ({
    createAlert,
    refreshData,
    globalorder_id,
    locale
}) => (order_id) => () => {

    const caller = wrapClientApiCall({
        url: 'globalorderlocales/mark-order-departed',
    });

    const params = {
        globalorder_id: globalorder_id,
        locale: locale,
        order_id,
    };

    return caller({ params })
        .then(() => {
            refreshData()
        })
        .catch((err) => {
            createAlert(err)
        });
}