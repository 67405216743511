import Badge from 'react-bootstrap/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon'

export const AccueilQueue = ({
  removeOrderFromQueue,
  orderTables,
  queue,
  reloadGopps,
  activeTables,
  handleToggleTable,
  tables
}) => {

  return (
    <Container fluid className="mb-3">

      <Row>

        {orderTables && orderTables.length > 0 ? orderTables.map(table =>
          <Dropdown className='mr-2' autoClose={false}>

            <Dropdown.Toggle size="lg" variant="success" id="dropdown-order-tables">
              <span className="text-capitalize">Table {table.alias}</span> <Badge variant='warning'>{table.orders.length}</Badge>
            </Dropdown.Toggle>

            <Dropdown.Menu>

              {table.orders && table.orders.length > 0 ? table.orders.map(order =>
                <Dropdown.ItemText style={{ minWidth: '25em', }}>
                  <Badge variant='secondary'>Place {order.place}</Badge>&nbsp;{order.consumer_name ? order.consumer_name : order.email}
                </Dropdown.ItemText>)
                : ''}

            </Dropdown.Menu>
          </Dropdown>
        ) : ''}

        {queue && queue.orders && queue.orders.length > 0 ?
          <Dropdown className='mr-2' autoClose={false}>

            <Dropdown.Toggle size="lg" variant="light" id="dropdown-queue">
              Queue <Badge variant='dark'>{queue.orders.length}</Badge>
            </Dropdown.Toggle>

            <Dropdown.Menu>

              {queue.orders.map(order =>
                <Dropdown.Item onClick={removeOrderFromQueue(order._id)}>
                  <Icon name='eject' textRight />
                  {order.consumer_name ? order.consumer_name : order.email}
                </Dropdown.Item>)}

            </Dropdown.Menu>
          </Dropdown>
          : ''}


        <Button className='mr-2' size='lg' onClick={reloadGopps} variant='secondary'>
          <Icon name='sync' />
        </Button>

        {/* TODO: integrate a better way of blocking tables */}
        <Dropdown className='mr-2'>

          <Dropdown.Toggle size="lg" variant="secondary" id="dropdown-block-table">
            Bloquer une place
          </Dropdown.Toggle>

          <Dropdown.Menu>

            {activeTables && activeTables.length > 0 ? activeTables.map(table => {
              return (<>
                {tables[table.table].active &&
                  <Dropdown.Item onClick={handleToggleTable(table.table, table.place)}>
                    {table.active ?
                      <Badge variant='success'>
                        <Icon name={'stop-circle'} textRight />Table {tables[table.table].name}, place {table.place + 1}
                      </Badge>
                      :
                      <Badge variant='danger'>
                        <Icon name={'play-circle'} textRight />Table {tables[table.table].name}, place {table.place + 1}
                      </Badge>
                    }
                  </Dropdown.Item>
                }
              </>)
            })
              : ''}
          </Dropdown.Menu>
        </Dropdown>

      </Row>
    </Container>
  )
}