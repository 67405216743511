import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

import { formatProducerProductIds } from "../helpers";

const sortByProducerProductId = (a, b) => {
    if (a < b) return -1;
    if (b < a) return 1;
    return 0;
}

export const validateOrder = ({
    order_id,
    globalorder_id,
    items,
    reduced,
    removed,
    currentOrder,
    checkedItems,
    createAlert,
    setValidated
}) => {

    const currentLists = {
        items: items.sort(sortByProducerProductId),
        reduced: reduced.sort(sortByProducerProductId),
        removed: removed.sort(sortByProducerProductId)
    }
    const { producerproductIds } = formatProducerProductIds({ items, reduced, removed });
    const changes = {};

    if (checkedItems.length !== producerproductIds.length) {
        return Promise.resolve(); // not all items have been validated...
    }

    for (let key of Object.keys(currentLists)) {
        let previous = currentOrder[key].slice(0).sort(sortByProducerProductId);
        let hasChanged = JSON.stringify(currentLists[key]) !== JSON.stringify(previous)
        changes[key] = hasChanged ? currentLists[key] : previous;
    }

    const caller = wrapClientApiCall({
        url: 'globalorders/validate-order-nr',
    });

    return caller({
        params: {
            globalorder_id,
            order_id,
            userId: '2W8X2SaMhytesy4ne', // WARN
            items: changes['items'],
            reduced: changes['reduced'],
            removed: changes['removed']
        }

    })
        .then(setValidated(true))
        .catch(err => { createAlert(err) })
};