import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'boutiqueday',
  initialState: {
    boutiqueday: {}
  },
  reducers: {
    fetchBoutiqueDayRequest(state){
      state.loading = true;
      state.error = undefined;
    },
    fetchBoutiqueDaySuccess(state, action){
      state.boutiqueday = action.payload;
      state.loading = false;
    },
    fetchBoutiqueDayFailed(state, action){
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export { reducer, actions };