import getPaymentMethodTitle from '@kelbongoo/shared-methods/paymentMethods/getPaymentMethodTitle';
import formatDate from '@kelbongoo/shared-methods/utils/formatDate';
import formatMoney from '@kelbongoo/shared-methods/utils/formatMoney';

export const printReceipt = ({
    isMobile,
    createAlert,
    currentOrder,
    localeDocument,
}) => ({ items, reduced }) => {

    if (!isMobile) {
        createAlert(new Error('bluetooth unavailable'))
        return;
    }

    const payment =
        currentOrder &&
        currentOrder.payments &&
        currentOrder.payments.length > 0 &&
        currentOrder.payments[0];

    if (!payment) {
        createAlert(new Error('order not paid'))
        return;
    }

    const payment_type = getPaymentMethodTitle(payment.method);
    const allItems = [...(items || []), ...(reduced || [])];

    window.plugin.PosPrinter.printFormattedText(
        [
            {
                boutique: {
                    name: localeDocument.name,
                    address_street: localeDocument.address.street,
                    address_zip: localeDocument.address.zip,
                    address_city: localeDocument.address.city,
                },
                date: formatDate.run(new Date(), 'dddd DD MMMM HH:mm'),
                order_id: currentOrder._id,
                payment_type,
                items: allItems.map((item) => {
                    let product_name =
                        item.product_name.length > 25
                            ? `${item.product_name.substring(0, 10)}...`
                            : item.product_name;
                    let consumer_price = item.percentage ? item.consumer_price * (100 - item.percentage) / 100 : item.consumer_price;
                    return {
                        product_name,
                        quantity: item.quantity,
                        consumer_price: formatMoney.run(consumer_price),
                        percentage: item.percentage ? item.percentage : undefined,
                        total_price: formatMoney.run(consumer_price * item.quantity),
                    };
                }),
                amount_total: formatMoney.run(currentOrder.getAmountTotal()),
                amount_total_ht: formatMoney.run(currentOrder.getAmountTotalHT()),
                amount_tva: formatMoney.run(currentOrder.getAmountTotalTVA()),
            }
        ],
        () => { }, // successStatement
        (error) => createAlert(error)
    );

}