import { BATTERY_DANGER_TRESHOLD, BATTERY_WARNING_TRESHOLD } from './constants.js'

export const batteryClass = (battery) => {
  if (battery < BATTERY_DANGER_TRESHOLD) {
    return 'bg-danger';
  }
  else if (battery < BATTERY_WARNING_TRESHOLD) {
    return 'bg-warning';
  }
  else {
    return '';
  }
};