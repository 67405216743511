export const rssiText = (rssi) => {
    // -125: near limit of detection
    // -25: very close
    if (rssi < -100) {
        return 'à peine preceptible';
    }
    else if (rssi < -80) {
        return 'faible';
    }
    else if (rssi < -60) {
        return 'assez bon';
    }
    else if (rssi < -40) {
        return 'bon';
    }
    else {
        return 'très bon';
    }
};
