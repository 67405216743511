import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import { createAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';

import { OrdersDetail } from '@kelbongoo/shared-client-react-v2/dist/components/OrdersDetail';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';

import { ErrorBoundary } from '../../ui/ErrorBoundary';
import {
    fetchDetail,
    boutiqueproducerproductsFetch,
    producersGetAllNames,
    updateItem,
    addLocalePayment,
    addComment,
} from './actions';
import selectors from '../../../selectors';

import { getAmountPaid, printReceipt } from './helpers';

import { getBacDetail as getBacDetailBase } from './actions/getBacDetail';

/**
 * 
 * @returns a react component showing order details
 */
export const OrderDetailBase = () => {

    const { order_id } = useParams();

    const isMobile = !!window.cordova;

    const dispatch = useDispatch();

    const { currentLocale: locale } = useSelector(state => state.locales);
    const localeDocument = selectors.getCurrentLocaleDocument();
    const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
    const { gopps } = useSelector(state => state.gopps);

    const [loading, setLoading] = useState(true);
    const [currentOrder, setCurrentOrder] = useState();
    const [bacs, setBacs] = useState([]);
    
    const getBacDetail = getBacDetailBase({
        setLoading,
        createAlert,
        locale,
        globalorder_id
    })

    const loadData = () => {
        fetchDetail({
            createAlert: arg => dispatch(createAlert(arg)),
            order_id,
            setLoading,
            setCurrentOrder,
            getBacDetail,
            setBacs
        })
    }

    useEffect(loadData, [dispatch, order_id]);

    useEffect(() => {
        if (isMobile) {
            if (!window.plugin.PosPrinter || !window.plugin.PosPrinter.printFormattedText) {
                console.warn('Unable to launch PosPrinter');
            } else {
                console.info('PosPrinter plugin launched');
            }
        }
    }, [isMobile]);

    return (
        <Container className='px-5' fluid>

            {loading || !currentOrder ?
                <LoadingSpinner />
                :
                <>
                    <Button className='mb-2' onClick={() => window.history.back()} variant='outline-secondary'>
                        <Icon name='arrow-left' textRight /> Précédent
                    </Button>

                    <OrdersDetail
                        /**shared props**/
                        itemReferences={gopps}
                        currentOrder={currentOrder}

                        /**not found**/
                        allowAddProducts={!currentOrder.paidAt}
                        allowCancel={false}
                        hidePricing={false}
                        hideQuantity={false}

                        /**commentsProps**/
                        addComment={addComment}
                        // addComment
                        allowAddComment={false}
                        // allowPhotoComments
                        // getCommentPicture
                        // hideAddComment
                        isMobile={isMobile}
                        showComments={true}
                        // uploadCommentImage

                        /**upperLeftProps**/
                        // addHandlingCharge
                        // allowValidation
                        // backButtonPath={path}
                        noConsumerLink={true}
                        // showAddHandlingChargeButton
                        showCreatedCredits={false}
                        showOrderBacs={true}
                        showOrderLocale={true}
                        validateOrder={() => { }}
                        Link={Link}

                        /**listProps**/
                        updateItem={updateItem({ createAlert, order_id, setCurrentOrder })}
                        allowEdit={true}
                        allowCredits={true}
                        // allowUpdateAddedCharges
                        // updateAddedCharges

                        /**paymentProps**/
                        // amountTotalVentilated
                        cancelPayment={() => { }}
                        // consumerCredits
                        getAmountPaid={getAmountPaid}
                        addPayment={addLocalePayment({ globalorder_id, locale, createAlert, order_id, setCurrentOrder, refreshData: loadData })}
                        // paymentModalLink
                        // keysToCopy
                        printReceipt={printReceipt({ isMobile, createAlert, currentOrder, localeDocument })}
                        allowPayment={!currentOrder.paidAt}
                        showAlternativePaymentMethods={true}
                        showCancelPayment={false}
                        showPrintReceipt={isMobile}

                        /**addItemProps**/
                        allowAddItemQuantityInput={true}
                        allowSellByWeight={true}
                        // addItemDefaultQuantity,
                        // addItemExtraOptions,
                        maxAddItemQuantity={15}
                        runAddItemExtraSearch={producersGetAllNames(createAlert)}
                        runAddItemSearch={boutiqueproducerproductsFetch({ locale, createAlert })}
                    />
                </>
            }

        </Container>
    )
}

export const OrderDetail = () => (
    <ErrorBoundary>
        <EnsureGlobalOrderWrapper>
            <OrderDetailBase />
        </EnsureGlobalOrderWrapper>
    </ErrorBoundary>
)