import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import { PlanningDay } from '@kelbongoo/shared-client-react-v2/dist/components/PlanningDay';
import { ProductInfos } from '@kelbongoo/shared-client-react-v2/dist/components/ProductInfos';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import { getDistributionData } from '../../../actions/getDistributionData';
import { formatEvents } from './helpers';

const HomeBase = () => {

	const dispatch = useDispatch();

	const { list: productInfosList, loading: productInfosLoading } = useSelector(state => state.productinfos);
	const { list: orderCountsList, loading: orderCountsLoading } = useSelector(state => state.ordercounts);
	const { list: tasksList, loading: tasksLoading } = useSelector(state => state.tasks);

	let eventsReady = !(orderCountsLoading && tasksLoading);

	useEffect(() => {dispatch(getDistributionData())}, [dispatch])

	return (
		<Container fluid>
			<h3>Distribution</h3>

			<Row className="mt-8 mb-8">
				<Col md={6} xs={12}>
					<Card>
						<Card.Header>Évènements du jour</Card.Header>
						<PlanningDay
							isHome={true}
							planning={false}
							events={formatEvents({ tasks: tasksList, orderCounts: orderCountsList })}
							ready={eventsReady}
							Link={({ children, ...props }) => <div {...props}>{children}</div>}
						/>
					</Card>
				</Col>
				<Col md={6} xs={12}>
					<Card>
						<Card.Header>Infos produits</Card.Header>
						<ProductInfos
							ready={!productInfosLoading}
							infos={productInfosList}
							// maxHeight={'30em'}
						/>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

export const Home = props => (
	<ErrorBoundary>
		<EnsureGlobalOrderWrapper>
			<HomeBase {...props} />
		</EnsureGlobalOrderWrapper>
	</ErrorBoundary>
)