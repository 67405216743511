import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";


export const toggleBacPickedUp = ({
    createAlert,
    bac_id,
    setBacs,
    bacs,
    formatBacPickedUp,
    loadBacs
}) => {

    const savedDoc = bacs.find(bac => bac._id === bac_id);
    setBacs(formatBacPickedUp(bacs, bac_id, ('auto')));

    const caller = wrapClientApiCall({
        url: 'orders/toggle-bac-picked-up',
    })
    return caller({
        params: {
            bac_id: bac_id
        },
    })
        .then(({ updatedDoc }) => {
            setBacs(formatBacPickedUp(bacs, bac_id, updatedDoc.pickedUpAt));
        })
        .catch((err) => {
            setBacs(formatBacPickedUp(bacs, bac_id, savedDoc.pickedUpAt));
            createAlert(err)
        })
        .finally(
            loadBacs()
        );
};