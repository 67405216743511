export const updateResults = ({
  localeDocument,
  isPartner,
  setBackgroundLoading,
  setTemperatures,
  setLoading,
  createAlert,
  temperatureRecorders
}) => {

  if (!temperatureRecorders || !localeDocument || !isPartner) {
    setLoading(false);
    return;
  }

  const serials = temperatureRecorders.map(r => r.serial);

  setBackgroundLoading(true);

  window.BlueMaestroReader.updateResults(
    (res) => {
      var resFiltered = res
        .filter(temperatureRecord => serials.includes(temperatureRecord.id));
      setTemperatures(resFiltered);
      setLoading(false);
      setBackgroundLoading(false);
    },
    (err) => {
      createAlert(err);
    }
  );
};