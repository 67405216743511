import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const markNondispatchDelivered = ({
    createAlert,
    refreshData
}) => (nd_id) => () => {
    const caller = wrapClientApiCall({
        url: 'orders/mark-nondispatch-delivered',
    });

    return caller({ params: { nondispatch_id: nd_id, } })
    .then(() => {
        refreshData()
    })
        .catch((err) => {
            createAlert(err)
        });
}