export const toggleCheckedItem = ({
    checkedItems,
    setCheckedItems,
}) => producerproduct_id => () => {
    let checkedItemsCopy = checkedItems;
    const ind = checkedItems.indexOf(producerproduct_id)

    if (ind === -1) {
        checkedItemsCopy.push(producerproduct_id)
    }
    else {
        checkedItemsCopy.splice(ind, 1)
    }
    setCheckedItems(checkedItemsCopy);
}