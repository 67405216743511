import { Link } from 'react-router-dom';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import Badge from 'react-bootstrap/Badge';
import { Order } from '../../../../models/Order';


export const OrdersNrRow = ({ item }) => {

  let order = new Order(item)

  return (
    <tr key={order._id}>

      <td>
        <Link to={`/orders-nr/${order._id}`} >
          {order.consumer_name ? order.consumer_name : order.email}
        </Link>
      </td>

      <td>
        {order.getStatusTitle()}
      </td>

      <td>
        {order.nrValidatedAt ?
          <Badge variant='success'><Icon name='check' textRight />Commande NR validée</Badge>
          :
          <Badge variant='danger'><Icon name='exclamation-triangle' textRight />Commande NR en attente</Badge>
        }
      </td>

    </tr>
  )
}