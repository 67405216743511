const transferQuantity = require('@kelbongoo/shared-methods/commonordermethods/transferQuantity').transferQuantity

export const updateItemReduction = ({
    setItems,
    setReduced,
    items, 
    reduced
}) => producerproduct_id => ({ unreduce, quantity, percentage }) => {

  let reducedInd = reduced.findIndex(i => i.producerproduct_id === producerproduct_id);
  if(unreduce){
    ({ source: reduced, destination: items } = transferQuantity({ 
      source: reduced, 
      destination: items,
      destinationKey: 'items',
      producerproduct_id, 
      quantity: reduced[reducedInd].quantity,
      keysToCopy: ['consumer_price','producerproduct_id','quantity','tva']
    }, {}, true))
  }
  else {
    ({ source: items, destination: reduced } = transferQuantity({ 
      source: items, 
      destination: reduced,
      destinationKey: 'reduced',
      producerproduct_id, 
      quantity: quantity,
      keysToCopy: ['consumer_price','producerproduct_id','quantity','tva']
    }, { percentage }, true))
  }
  setItems(items);
  setReduced(reduced);
}