import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { formatOrder } from '../helpers';

export const getBacDetail = ({
  createAlert,
  locale,
  globalorder_id
}) => ({ bac, couloir }) => {

  const caller = wrapClientApiCall({
    url: 'globalorderlocales/get-bac-detail',
  });


  return caller({
    params: {
      locale,
      globalorder_id,
      bac,
      couloir
    }
  })
    .then(({ items }) => { return ({ bac: bac, couloir: couloir, items: items }) })
    .catch(err => { createAlert(err) })
};