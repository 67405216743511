import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

const fields = {
  paidAt: 1,
  consumer_name: 1,
  nrValidatedAt: 1,
  status: 1,
};

export const refreshItems = ({
  globalorder_id,
  page,
  locale,
  currentQuery = {},
  setItems,
  setCount,
  setLoading,
  createAlert,
  docsPerPage,
}) => {

  const options = { 
    fields, 
    page,
    limit: docsPerPage, 
    sort: { consumer_name: 1 } 
  }

  const params = Object.assign(
    {
      locale: locale,
      globalorder_id: globalorder_id,
      status: { $in: [4, 5] },
    },
    currentQuery || {}
  );

  const caller = wrapClientApiCall({
    url: 'orders/fetch'
  })

  setLoading(true);

  return caller({
    params,
    options,
  })
    .then(({ items, count }) => {
      setItems(items);
      setCount(count);
      setLoading(false);
    })
    .catch(error => {
      createAlert({ message: error.message })
      setLoading(false);
      return error;
    });

}