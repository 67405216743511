// @ts-check
import { Link } from "react-router-dom";

import ListGroup from "react-bootstrap/ListGroup";

import Badge from "react-bootstrap/Badge";

// @ts-ignore
import { Icon } from "@kelbongoo/shared-client-react-v2/dist/components/Icon";
// @ts-ignore
import { ValidationButton } from "@kelbongoo/shared-client-react-v2/dist/components/ValidationButton";
// @ts-ignore
import { TouchActivatedMethod } from "@kelbongoo/shared-client-react-v2/dist/components/TouchActivatedMethod";

import styles from "./TableItem.module.css";
import { NdProductItem } from "./NdProductItem";

const COULOIRS = {
  frais: 0,
  sec: 1,
};

/**
 *
 * @param {object} props
 * @param {boolean} props.paid
 * @returns
 */
export const PaidStatus = ({ paid }) => {
  return paid ? (
    <span className={styles.PaidBadge} style={{ background: "var(--success)" }}>
      Déjà réglé
    </span>
  ) : (
    <span className={styles.PaidBadge} style={{ background: "var(--red)" }}>
      A régler
    </span>
  );
};

/**  shared-methods/commonproductmethods/nondispatchlocations.js  */
export const TableItem = ({
  place,
  order,
  markNondispatchDelivered,
  undoNondispatchDelivery,
  markOrderDeparted,
  markBacsDelivered,
  showAccueilNd,
  showLibreServiceNd,
  showFraisNd,
  showSecNd,
}) => {
  const bacsFrais =
    order &&
    order.bacs &&
    order.bacs.length > 0 &&
    order.bacs.filter((bac) => bac.couloir === COULOIRS["frais"]);
  const areBacsFraisDelivered =
    bacsFrais &&
    bacsFrais.length > 0 &&
    bacsFrais.every((bac) => bac.deliveredAt);
  const bacsSec =
    order &&
    order.bacs &&
    order.bacs.length > 0 &&
    order.bacs.filter((bac) => bac.couloir === COULOIRS["sec"]);
  const areBacsSecDelivered =
    bacsSec && bacsSec.length > 0 && bacsSec.every((bac) => bac.deliveredAt);
  const nondispatch =
    order && order.nondispatch && order.nondispatch.length > 0
      ? order.nondispatch
      : [];

  /** @type {import("./NdProductItem").NdItem[]} */
  let ndFiltered = nondispatch;
  //   let ndFiltered = [
  //     {
  //       _id: "test",
  //       producer_name: "Marc Bontant",
  //       product_name: "Fraise",
  //       unit_display: "500g",
  //       location: 0,
  //     },
  //     {
  //       _id: "test",
  //       producer_name: "Les Jardins de Sacy",
  //       product_name: "Gros Poireau",
  //       unit_display: "2 pièce",
  //       location: 1,
  //       deliveredAt: "c'est livré",
  //     },
  //     {
  //       _id: "test",
  //       producer_name: "Jardin de mon plaisir",
  //       product_name: "Courge des enfers",
  //       unit_display: "2 pièce",
  //       location: 1,
  //     },
  //     {
  //       _id: "test",
  //       producer_name: "Jardin de mon plaisir",
  //       product_name: "Courge des eaux",
  //       unit_display: "2 pièce",
  //       location: 1,
  //     },
  //   ];
  if (!showAccueilNd) {
    ndFiltered = ndFiltered.filter((nd) => nd.location !== 2);
  }
  if (!showLibreServiceNd) {
    ndFiltered = ndFiltered.filter((nd) => nd.location !== 3);
  }
  if (!showFraisNd) {
    ndFiltered = ndFiltered.filter((nd) => nd.location !== 0);
  }
  if (!showSecNd) {
    ndFiltered = ndFiltered.filter((nd) => nd.location !== 1);
  }

  const markBacsDeliveredBase = (couloir) => () =>
    markBacsDelivered({ order, order_id: order._id })(couloir);

  if (place.order_id && order) {
    return (
      <ListGroup.Item key={place.alias} className={styles.TableItem}>
        <header className={styles.Header}>
          <h5 style={{ margin: 0, fontSize: "var(--table-font-size)" }}>
            <Link to={`/accueil/${order._id}`}>
              {order.consumer_name || order.email}
            </Link>
          </h5>
          <PaidStatus paid={order.isPaid} />

          {order.first_order && (
            <Badge variant="warning" className="mx-1">
              <Icon name="star" />
            </Badge>
          )}

          <div style={{ marginLeft: "auto" }}>
            <TouchActivatedMethod
              callback={markOrderDeparted(order._id)}
              timeout={1000}
            >
              <ValidationButton
                buttonText={place.alias}
                buttonIcon="check-circle"
                buttonClasses="btn btn-secondary"
                onValidation={() => {}}
                enabled={true}
              />
            </TouchActivatedMethod>
          </div>
        </header>
        {/* OrderBacs */}
        <div className={styles.OrderBacs}>
          {bacsFrais && bacsFrais.length > 0 ? (
            bacsFrais.length > 1 ? (
              bacsFrais.map((bac) => (
                <ValidationButton
                  noButtonIcon="true"
                  buttonText={bac.number}
                  onValidation={markBacsDeliveredBase(COULOIRS["frais"])}
                  enabled={!areBacsFraisDelivered}
                  buttonClasses={
                    areBacsFraisDelivered
                      ? "btn-light btn-lg mr-1 p-1 "
                      : "btn-primary btn-lg mr-1 font-weight-bold"
                  }
                />
              ))
            ) : (
              <ValidationButton
                noButtonIcon="true"
                buttonText={bacsFrais.map((bac) => `${bac.number}`)}
                onValidation={markBacsDeliveredBase(COULOIRS["frais"])}
                enabled={!areBacsFraisDelivered}
                buttonClasses={
                  areBacsFraisDelivered
                    ? "btn-light btn-lg"
                    : "btn-primary btn-lg font-weight-bold"
                }
              />
            )
          ) : (
            <ValidationButton
              noButtonText="true"
              buttonIcon="times"
              enabled={false}
              buttonClasses="btn-light btn-lg"
            />
          )}
          {bacsSec && bacsSec.length > 0 ? (
            bacsSec.length > 1 ? (
              bacsSec.map((bac) => (
                <ValidationButton
                  noButtonIcon="true"
                  buttonText={bac.number}
                  onValidation={markBacsDeliveredBase(COULOIRS["sec"])}
                  enabled={!areBacsSecDelivered}
                  buttonClasses={
                    areBacsSecDelivered
                      ? "btn-light btn-lg mr-1"
                      : "btn-success btn-lg mr-1 font-weight-bold"
                  }
                />
              ))
            ) : (
              <ValidationButton
                noButtonIcon="true"
                buttonText={bacsSec.map((bac) => `${bac.number}`)}
                onValidation={markBacsDeliveredBase(COULOIRS["sec"])}
                enabled={!areBacsSecDelivered}
                buttonClasses={
                  areBacsSecDelivered
                    ? "btn-light btn-lg"
                    : "btn-success btn-lg font-weight-bold"
                }
              />
            )
          ) : (
            <ValidationButton
              noButtonText="true"
              buttonIcon="times"
              enabled={false}
              buttonClasses="btn-light btn-lg"
            />
          )}
        </div>
        {/* NDProducts */}
        <ListGroup variant="flush">
          {ndFiltered.map((nd) => (
            <NdProductItem
              nd={nd}
              order={order}
              undoNondispatchDelivery={undoNondispatchDelivery}
              markNondispatchDelivered={markNondispatchDelivered}
            />
          ))}
        </ListGroup>
      </ListGroup.Item>
    );
  }

  return <PlaceholderItem place={place} />;
};

function PlaceholderItem(place) {
  return (
    <div style={{ height: "48px" }}>Personne en position {place.alias}</div>
  );
}
