import { Link } from 'react-router-dom';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { MoneyDisplay } from '@kelbongoo/shared-client-react-v2/dist/components/MoneyDisplay';
import Badge from 'react-bootstrap/Badge';
import Table from 'react-bootstrap/Table';
import { Order } from '../../../../models/Order';

export const OrdersRow = ({item}) => {

  const {
    gopps,
  } = item;

let order = new Order(item)

  return (
    <tr key={order._id}>

      {/* Consommateurice */}
      <td>
        <>
          <Link to={`/caisse/orders/${order._id}`} >
            {order.consumer_name ? order.consumer_name : order.email}
          </Link>
        </>
        <>
            {order.first_order ?
              <Badge variant='warning' className='mr-2'>
                <Icon name='star' textRight />
                Première commande !
              </Badge>
              : ''}

          </>
      </td>

      {/* Manquants */}
      <td>
        {order.removed && order.removed.length > 0 ?
          <Table size="sm" borderless={true} style={{ margin: '0' }} className='text-sm' >
            {order.removed.map(item => {
              let gopp = gopps.find(g => g.producerproduct_id === item.producerproduct_id)
              if (gopp) {
                return <tr>
                  <td >
                    <b>{gopp.product_name}</b><br />
                    <i className='text-muted'>{gopp.producer_name}</i><br />
                    <i className='text-muted'>{gopp.unit_display}</i>
                  </td>
                  <td width='5%'>
                    <Badge variant='secondary'>-{item.quantity}</Badge>
                  </td>
                </tr>
              } else {
                return <tr>
                  <td >
                    <b>Inconnu</b><br />
                    <i className='text-muted'>Inconnu</i><br />
                    <i className='text-muted'>Inconnu</i>
                  </td>
                  <td width='5%'>
                    <Badge variant='secondary'>-{item.quantity}</Badge>
                  </td>
                </tr>
              }
            })}
          </Table> :
          <>
            <i className='text-muted'>-</i>
          </>
        }
      </td>

      {/* Total */}
      <td className='text-center'>
        {order.getAmountTotal ?
          <MoneyDisplay
            value={order.getAmountTotal()}
          />
          : <i className='text-muted'>-</i>
        }
      </td>

      {/* Réglée ? */}
      <td className='text-center'>
        {order.paidAt ?
          <Badge variant='success' className='mr-2'>
            <Icon name='check' />
          </Badge>
          :
          <Badge variant='danger' className='mr-2'>
            <Icon name='exclamation-triangle' />
          </Badge>
        }
      </td>
    </tr>
  )
}