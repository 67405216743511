import model from '@kelbongoo/shared-methods/orders/model';
import collection from '@kelbongoo/shared-methods/orders/collection';

class Order {

  constructor(data){

    Object.assign(this, data);
  }
}

Object.assign(
  Order.prototype,
  model
);

Object.assign(
  Order,
  collection
);

export {
  Order
}