import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

import { actions } from '../reducers/boutiqueday';


export const boutiquedaysFetch = () => (dispatch, getState) => {

    const { locales: { currentLocale: locale } } = getState();

    const {
        fetchBoutiqueDayFailed,
        fetchBoutiqueDayRequest,
        fetchBoutiqueDaySuccess
    } = actions;


    const params = {
        boutique: locale
    };


    const options = { options: { limit: 1 } }

    const caller = wrapClientApiCall({
        url: 'boutiquedays/fetch',
    });

    dispatch(fetchBoutiqueDayRequest());

    return caller({params, options})
        .then(({ items }) => {
            dispatch(fetchBoutiqueDaySuccess(items[0]))
        })
        .catch((error) => {
            dispatch(fetchBoutiqueDayFailed(error.message || error.name))
        });
};