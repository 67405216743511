import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container'

import { createAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';

import { BoutiqueOrdersDetail } from '@kelbongoo/shared-client-react-v2/dist/components/BoutiqueOrdersDetail';

import { ErrorBoundary } from '../../ui/ErrorBoundary';
import {
    boutiqueproducerproductsFetch,
    producersGetAllNames,
    addLocalePayment,
    fetchConsumers,
    fetchConsumerDetails
} from './actions';

import { getAmountPaid, printReceipt } from './helpers';

/**
 * 
 * @returns a react component showing order details
 */
export const BoutiqueOrderNewBase = () => {

    const isMobile = !!window.cordova;

    const { currentLocale: locale } = useSelector(state => state.locales);
    const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);

    const [items, setItems] = useState([])
    const [reduced, setReduced] = useState([])
    const [orderId, setOrderId] = useState()

    if (orderId) {
        return (<Redirect to={`/caisse/boutiqueorders/${orderId}`} />)
    }

    return (
        <Container className='px-5' fluid>

            <BoutiqueOrdersDetail
                /**shared props**/
                items={items}
                reduced={reduced}

                /** payment params*/
                allowAddPayment={true}
                getAmountPaid={getAmountPaid}
                printReceipt={printReceipt}
                showPrintReceipt={isMobile}
                addPayment={addLocalePayment({ globalorder_id, locale, createAlert, setOrderId })}
                showCancelPayment={false}

                /**add item params */
                runSearch={boutiqueproducerproductsFetch({ locale, createAlert })}
                // setItem=''
                setItems={setItems}
                setReduced={setReduced}
                runExtraSearch={producersGetAllNames(createAlert)}
                // placeholderText=''
                allowSellByWeight={true}

                /**consumer search params */
                fetchConsumerCredit={fetchConsumerDetails({ createAlert })}
                runConsumerSearch={fetchConsumers({ createAlert })}

                /**comments params */
                allowAddComment={false}
                // addComment=''

                showClearOrder={true}

                /** upper left params */
                Link={Link}
                noConsumerLink={true}
                showOrderBoutique={false}
            />

        </Container>
    )
}

export const BoutiqueOrderNew = () => (
    <ErrorBoundary>
        <EnsureGlobalOrderWrapper>
            <BoutiqueOrderNewBase />
        </EnsureGlobalOrderWrapper>
    </ErrorBoundary>
)