import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const fetchDetail = ({
  order_id,
  setLoading,
  createAlert,
  setCurrentOrder,
  globalorder_id,
  locale,
  setAvailableNRBacs,
  setItems,
  setReduced,
  setRemoved,
  setNrBacs,
}) => {

  setLoading(true)

  const ordersFetchDetail = wrapClientApiCall({
    url: 'orders/fetch-detail',
  });

  const getAvailableNRBacs = wrapClientApiCall({
    url: 'globalorderlocales/get-available-nr-bacs'
  })

  return Promise.all([
    ordersFetchDetail({
      params: {
        order_id,
        getPayments: true
      }
    }),
    getAvailableNRBacs({
      params: {
        globalorder_id,
        locale
      }
    })
  ])
    .then(([{ order }, { bacs }]) => {
      setCurrentOrder(order)
      setAvailableNRBacs(bacs)
      setItems(order.items)
      setReduced(order.reduced)
      setRemoved(order.removed)
      setNrBacs(order.nr_bacs)
    })
    .catch(err => { createAlert(err) })
    .finally(setLoading(false))
};