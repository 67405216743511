const transferQuantity = require('@kelbongoo/shared-methods/commonordermethods/transferQuantity').transferQuantity

export const updateItemQuantity = ({
  setItems,
  setRemoved,
  setReduced,
  items,
  reduced,
  removed,
  loadData
}) => ({ producerproduct_id, currentQuantity, expectedQuantity }) => quantity => {

  let itemsCopy = items;
  let reducedCopy = reduced;
  let removedCopy = removed;

  const reducedInd = reducedCopy.findIndex(
    i => i.producerproduct_id === producerproduct_id
  )

  if (quantity > expectedQuantity) {
    return;
  }

  if (quantity === currentQuantity) {
    return;
  }

  if (quantity > currentQuantity) {
    ({ source: removedCopy, destination: itemsCopy } = transferQuantity({
      source: removedCopy,
      destination: itemsCopy,
      destinationKey: 'items',
      producerproduct_id,
      quantity: quantity - currentQuantity,
      keysToCopy: ['consumer_price', 'producerproduct_id', 'quantity', 'tva']
    }, {}, true))
    setItems(itemsCopy);
    setRemoved(removedCopy);
  }
  else {
    if (reducedInd > -1) {

      let reducedQuantity = reduced[reducedInd].quantity;
      if (reducedQuantity > currentQuantity - quantity) {
        ({ source: reducedCopy, destination: removedCopy } = transferQuantity({
          source: reducedCopy,
          destination: removedCopy,
          destinationKey: 'removed',
          producerproduct_id,
          quantity: currentQuantity - quantity,
          keysToCopy: ['consumer_price', 'producerproduct_id', 'quantity', 'reason', 'tva']
        }, {}, true))
        setReduced(reducedCopy);
        setRemoved(removedCopy);
      }
      else {
        reducedCopy.splice(reducedInd, 1);
        if (reducedQuantity < currentQuantity - quantity) {
          ({ source: itemsCopy, destination: removedCopy } = transferQuantity({
            source: itemsCopy,
            destination: removedCopy,
            destinationKey: 'removed',
            producerproduct_id,
            quantity: currentQuantity - quantity - reducedQuantity,
            keysToCopy: ['consumer_price', 'producerproduct_id', 'quantity', 'tva']
          }, {}, true))
          setReduced(reducedCopy);
          setRemoved(removedCopy);
          setItems(itemsCopy);
        }
      }
    }
    else {
      ({ source: itemsCopy, destination: removedCopy } = transferQuantity({
        source: itemsCopy,
        destination: removedCopy,
        destinationKey: 'removed',
        producerproduct_id,
        quantity: currentQuantity - quantity,
        keysToCopy: ['consumer_price', 'producerproduct_id', 'quantity', 'tva']
      }, {}, true));
      setRemoved(removedCopy);
      setItems(itemsCopy);
    }
  }
}
