import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const updateNrBacs = ({
    order_id,
    currentOrder,
    setCurrentOrder,
    createAlert,
    setNrBacs
}) => ({nr_bacs}) => {


    const caller = wrapClientApiCall({
        url: 'orders/update-nr-bacs',
    });

    return caller({
        params: {
            order_id,
            nr_bacs
        }
    })
        .then(({ updatedDoc }) => {
            let currentOrderCopy = currentOrder;
            currentOrderCopy.nr_bacs = updatedDoc.nr_bacs;
            setCurrentOrder(currentOrderCopy);
            setNrBacs(updatedDoc.nr_bacs)
        })
        .catch(err => {
            createAlert(err)
        })
};