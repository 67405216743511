import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";


export const addLocalePayment = ({
    globalorder_id,
    locale,
    createAlert,
    setOrderId
}) => ({
    items,
    reduced,
    payments,
    consumer_id,
}) => {
    
        if (payments.length === 0) return createAlert(new Error('no payments'));

        const payment = payments.pop(); // take the last payment in the array ie the most recent

        const caller = wrapClientApiCall({
            url: 'globalorderlocales/add-locale-payment',
        });


        return caller({
            params: {
                globalorder_id,
                locale,
                payment,
                added: items,
                added_reduced: reduced,
                consumer_id,
                userId: '2W8X2SaMhytesy4ne', // WARN
            }
        })
            .then(({ boutiqueorder }) => {
                setOrderId(boutiqueorder._id)
            })
            .catch(err => {
                createAlert(err)
            })
    };

