import React from 'react';
import { useSelector } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import selectors from '../../../selectors';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';


export const HeaderItemsLeft = () => {

  const { currentLocale } = useSelector(state => state.locales);
  const globalorderlocale = selectors.getCurrentGlobalOrderLocale();

  return (
    <>
      <Nav.Item style={{ display: 'inline-block' }}>
        <Alert variant='warning'>

          <div
            data-testid='current-locale'
            style={{ display: 'inline-block' }}
          >
            {currentLocale}
          </div>

          {
            globalorderlocale
            && globalorderlocale.distributions
            && globalorderlocale.distributions.length > 0
            && (
              <div data-testid='current-distribution' style={{ display: 'inline-block', marginLeft: '1em' }}>
                {formatDate(globalorderlocale.distributions[0].start, 'DD/MM')}
              </div>
            )
          }

          <div style={{ display: 'inline-block', marginLeft: '1em' }}>
            <Button size="sm" variant='outline-primary' onClick={() => window.open(`https://locaux.kelbongoo.com/globalorders/${globalorderlocale.globalorder_id}`, '_blank', 'toolbar=0,location=0,menubar=0')}>
              <Icon name='home' textRight /> Locaux
            </Button>
          </div>

        </Alert>
      </Nav.Item>

    </>
  )
}