// @ts-check
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderBase } from './HeaderBase';
// @ts-ignore
import { ErrorBoundary as ErrorBoundaryBase } from '@kelbongoo/shared-client-react-v2/dist/components/ErrorBoundary';
import { HeaderItemsRight } from '../HeaderItemsRight';
import { HeaderItemsLeft } from '../HeaderItemsLeft';
import 'bootstrap/dist/css/bootstrap.min.css';

const ErrorBoundary = ErrorBoundaryBase({});

export const Header = () => {

	const dispatch = useDispatch();

  return (
		<ErrorBoundary>
			<HeaderBase 
				HeaderItemsRight={HeaderItemsRight}
				HeaderItemsLeft={HeaderItemsLeft}
				dispatch={dispatch}
				useSelector={useSelector}
				showBottomBorder={true}
			/>
		</ErrorBoundary>
	)
}