import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const markArrived = ({
  globalorder_id,
  locale,
  createAlert,
  loadData,
  loadQueueData,
}) => ({ order_id }) => ({ table, place } = {}) => {

  const caller = wrapClientApiCall({
    url: 'globalorderlocales/mark-order-arrived',
  })

  const params = {
    globalorder_id,
    locale,
    order_id
  }

  if (table && place) {
    params.table = parseInt(table)
    params.place = parseInt(place)
  }

  return caller({
    params
  })
    .then(() => {
      loadData()
      loadQueueData()
    })
    .catch((err) => createAlert(err));
};