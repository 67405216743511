import React, { FC } from "react";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
// @ts-ignore
import { TouchActivatedMethod } from "@kelbongoo/shared-client-react-v2/dist/components/TouchActivatedMethod";
// @ts-ignore
import { HeaderBaseMain } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/HeaderBase/HeaderBaseMain/index";
import {
  logoutUser,
  closeAlert,
  // @ts-ignore
} from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/actions";

import './HeaderBase.css';

export interface HeaderBaseProps {
  dispatch: (p: any) => any;
  useSelector: (p: any) => any;
  HeaderItemsLeft: React.ReactNode;
  HeaderItemsRight: React.ReactNode;
  showBottomBorder?: boolean;
}

export const HeaderBase: FC<HeaderBaseProps> = ({
  dispatch,
  useSelector,
  HeaderItemsLeft,
  HeaderItemsRight,
  showBottomBorder,
}) => {
  const { loggedIn, username, isLoggingOut } = useSelector(
    (state: any) => state.currentUser
  );

  const {
    alerts,
  }: { alerts: { type: string; message: string; _id: string }[] } = useSelector(
    (state: any) => state.alerts
  );
  const handleCloseAlert = (id: string) => dispatch(closeAlert(id));
  const onLogoutUser = () => dispatch(logoutUser());
  const reloadApp = () => window && window.location.reload();

  return (
    <>
      <header
        className="header-base__header"
        style={{
          borderBottom: showBottomBorder ? "1px solid #d3d3d3" : "inherit",
        }}
      >
        <Nav className={'header-base__nav'} defaultActiveKey="/">
          <TouchActivatedMethod timeout={2000} callback={reloadApp}>
            <Nav.Item>
              <Nav.Link as={"a"} href="/" style={{ background: "transparent" }}>
                <Image
                  src="https://cdn.kelbongoo.com/images/tomate.svg"
                  style={{ height: "36px", width: "36px" }}
                  role="logo"
                />
              </Nav.Link>
            </Nav.Item>
          </TouchActivatedMethod>

          {loggedIn && (
            <HeaderBaseMain
              onLogoutUser={onLogoutUser}
              HeaderItemsLeft={HeaderItemsLeft}
              HeaderItemsRight={HeaderItemsRight}
              username={username}
              isLoggingOut={isLoggingOut}
            />
          )}
        </Nav>
      </header>

      {alerts.length > 0 && (
        <div
          className="clearfix mb-1"
          data-testid="alerts-container"
          style={{ height: "52px" }}
        >
          <Alert
            variant={alerts[0].type === "success" ? "success" : "danger"}
            dismissible={true}
            className="m-0"
            onClose={() => handleCloseAlert(alerts[0]._id)}
            role="alert"
          >
            <b className="mr-2">
              {alerts[0].type === "success" ? "Succès !" : "Attention !"}
            </b>
            {alerts[0].message} &nbsp;
            {alerts.length > 1 ? (
              <Badge variant="light">{alerts.length}</Badge>
            ) : null}
          </Alert>
        </div>
      )}
    </>
  );
};
