import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { Order } from "../../../../models/Order";

const fields = {
  status: 1,
  items: 1,
  reduced: 1, // so i can calculate the amount total
  removed: 1, // for accueil to see missing products
  added: 1,
  added_reduced: 1,
  arrivedAt: 1,
  paidAt: 1,
  consumer_name: 1,
  nondispatch: 1,
  first_order: 1
}

const formatItems = (items, gopps, addComment, markArrived, unmarkArrived, isPartner, availablePlaces) => {
  return items.map(item => {
    let order = new Order(item)
    return {
      ...order,
      gopps,
      addComment,
      markArrived: markArrived({ order_id: item._id }),
      unmarkArrived: unmarkArrived(item._id),
      isPartner,
      availablePlaces,
    };
  })
};

export const refreshItems = ({
  globalorder_id,
  page,
  locale,
  currentQuery,
  setItems,
  setCount,
  setLoading,
  gopps = [],
  createAlert,
  docsPerPage,
  addComment,
  markArrived,
  unmarkArrived,
  isPartner,
  availablePlaces,
}) => {

  const options = {
    fields,
    page,
    limit: docsPerPage || 10,
    sort: { consumer_name: 1 },
  }

  const params = Object.assign(
    {
      globalorder_id,
      locale,
      addNondispatch: true,
      addComments: true,
      status: { $in: [2, 3, 6] }
    },
    currentQuery
  );

  const caller = wrapClientApiCall({
    url: 'orders/fetch'
  })

  setLoading(true);

  return caller({
    params,
    options,
  })
    .then(({ items, count }) => {

      let formattedItems = formatItems(items, gopps, addComment, markArrived, unmarkArrived, isPartner, availablePlaces)

      setItems(formattedItems);
      setCount(count);
      setLoading(false);
    })
    .catch(error => {

      createAlert({ message: error.message })
      setLoading(false);
      return error;
    });

}