import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

export const CouloirItem = ({
    bac,
    couloir,
    handleBacClick,
    showDeliveryTable
}) => {

    const placeVariant = !bac.placeName ? 'secondary' : couloir === 'frais' ? 'primary' : 'success'

    const placeAlias = bac.placeName ? `Place ${bac.placeName}` : 'Queue'

    return (
        <ListGroup.Item key={bac.number} action className='text-center position-relative' variant={bac.pickedUpAt ? 'success' : ''} onClick={handleBacClick}>
            <h1 className='display-4'>
                <strong>{bac.number}</strong>
            </h1>
            {bac.nondispatch && bac.nondispatch.length > 0 ?
                <ListGroup variant='flush'>
                    {bac.nondispatch.map(nd =>
                        <ListGroup.Item key={nd._id}>
                            {nd.product_name} ({nd.producer_name}) <Badge variant='secondary'>{nd.unit_display}</Badge> <Badge variant={placeVariant}>{nd.quantity}</Badge>
                        </ListGroup.Item>
                    )}
                </ListGroup>
                : ''}
            {showDeliveryTable ?
                <h3 className='position-absolute m-2' style={{ top: '0', right: '0' }}>
                    <Badge variant={placeVariant}>{placeAlias}</Badge>
                </h3>
                : ''}
        </ListGroup.Item>
    )
};