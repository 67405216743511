import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const addComment = ({
  createAlert,
  loadData
}) => (order_id) => (comment) => {

  const caller = wrapClientApiCall({
    url: 'orders/add-comment',
  })

  return caller({
    params: {
      order_id,
      comment
    }
  })
    .then(loadData())
    .catch((err) => createAlert({ message: err.message }));
};