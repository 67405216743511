export const buildFilters = () => {

  const searchFilter = {
    keys: ['consumer_name'],
    placeholder: 'Chercher par nom',
  };

  return {
    searchFilter
  }
}