// @ts-ignore
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import {
  refreshData,
  markNondispatchDelivered as markNondispatchDeliveredBase,
  undoNondispatchDelivery as undoNondispatchDeliveryBase,
  markOrderDeparted as markOrderDepartedBase,
  markBacsDelivered as markBacsDeliveredBase,
  refreshPlaces,
} from './actions';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import selectors from '../../../selectors';

import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import { TableHeader } from './TableHeader';
import { TableItem } from './TableItem';

import { useInterval } from '@kelbongoo/shared-client-react-v2/dist/utils/CustomHooks/useInterval';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
const DEFAULT_REFRESH_PERIOD_MS = 5000;

export const TableBase = () => {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [places, setPlaces] = useState([]);
  const [currentTable, setCurrentTable] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showAccueilNd, setShowAccueilNd] = useLocalStorage(
    'showAccueilNd',
    true
  );
  const [showLibreServiceNd, setShowLibreServiceNd] = useLocalStorage(
    'showLibreServiceNd',
    true
  );
  const [showFraisNd, setShowFraisNd] = useLocalStorage('showFraisNd', true);
  const [showSecNd, setShowSecNd] = useLocalStorage('showSecNd', true);

  const { currentLocale: locale } = useSelector((state) => state.locales);
  const { currentGlobalOrder: globalorder_id } = useSelector(
    (state) => state.globalorderlocales
  );
  const { init } = useSelector((state) => state.session);

  const localeDoc = selectors.getCurrentLocaleDocument();

  const tables = localeDoc.tables.map((table, index) => ({
    number: index,
    name: table.name,
  }));

  const filteredPlaces = places.filter((place) => place.table === currentTable);

  const loadData = () =>
    refreshData({
      createAlert,
      locale,
      globalorder_id,
      setLoading,
      setOrders,
      setPlaces,
    });

  const loadPlaces = () =>
    refreshPlaces({
      createAlert,
      locale,
      globalorder_id,
      setOrders,
      setPlaces,
    });

  const createAlert = (arg) => dispatch(createAlertBase(arg));

  const markNondispatchDelivered = markNondispatchDeliveredBase({
    createAlert,
    refreshData: loadPlaces,
  });
  const undoNondispatchDelivery = undoNondispatchDeliveryBase({
    createAlert,
    refreshData: loadPlaces,
  });
  const markOrderDeparted = markOrderDepartedBase({
    createAlert,
    refreshData: loadPlaces,
    globalorder_id,
    locale,
  });
  const markBacsDelivered = markBacsDeliveredBase({
    createAlert,
    loadPlaces,
    orders,
    setOrders,
  });

  useEffect(loadData, [init, globalorder_id, locale]);
  useEffect(loadPlaces, [currentTable]);

  useInterval(loadPlaces, DEFAULT_REFRESH_PERIOD_MS);

  return (
    <>
      {loading ? (
        <LoadingSpinner></LoadingSpinner>
      ) : (
        <>
          <TableHeader
            handleRefreshClick={loadPlaces}
            tables={tables}
            currentTable={currentTable}
            setCurrentTable={setCurrentTable}
            showAccueilNd={showAccueilNd}
            setShowAccueilNd={setShowAccueilNd}
            showLibreServiceNd={showLibreServiceNd}
            setShowLibreServiceNd={setShowLibreServiceNd}
            showFraisNd={showFraisNd}
            setShowFraisNd={setShowFraisNd}
            showSecNd={showSecNd}
            setShowSecNd={setShowSecNd}
          />

          {filteredPlaces && filteredPlaces.length > 0 ? (
            <ListGroup>
              {filteredPlaces.map((place) => (
                <TableItem
                  place={place}
                  order={orders.find((o) => o._id === place.order_id)}
                  markNondispatchDelivered={markNondispatchDelivered}
                  undoNondispatchDelivery={undoNondispatchDelivery}
                  markOrderDeparted={markOrderDeparted}
                  markBacsDelivered={markBacsDelivered}
                  showAccueilNd={showAccueilNd}
                  showLibreServiceNd={showLibreServiceNd}
                  showFraisNd={showFraisNd}
                  showSecNd={showSecNd}
                />
              ))}
            </ListGroup>
          ) : (
            <Container fluid>
              <h1>Aucune commande en attente</h1>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export const Table = (props) => (
  <ErrorBoundary>
    <EnsureGlobalOrderWrapper>
      <TableBase {...props} />
    </EnsureGlobalOrderWrapper>
  </ErrorBoundary>
);
