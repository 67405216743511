import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container';

import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { Datatable } from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { useInterval } from '@kelbongoo/shared-client-react-v2/dist/utils/CustomHooks/useInterval'
import { ensureGopps } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase/actions/ensureGopps';
import { actions as goppsActions } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase/goppsReducerBase';

import { ErrorBoundary } from '../../ui/ErrorBoundary';
import selectors from '../../../selectors';

import {
  addComment as addCommentBase,
  markArrived as markArrivedBase,
  refreshItems,
  refreshQueue,
  removeOrderFromQueue as removeOrderFromQueueBase,
  unmarkArrived as unmarkArrivedBase,
  toggleActive
} from './actions';
import { tableColumns, buildFilters } from './helpers';
import { AccueilRow } from './AccueilRow';
import { AccueilQueue } from './AccueilQueue';

import { loadPlaces } from '../../../actions/loadPlaces'

const DEFAULT_REFRESH_PERIOD_MS = 60000;
const QUEUE_REFRESH_PERIOD_MS = DEFAULT_REFRESH_PERIOD_MS;

const {
  fetchGoppsSuccess,
} = goppsActions



export const AccueilBase = () => {

  const dispatch = useDispatch();

  const { currentLocale: locale } = useSelector(state => state.locales);
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const { init } = useSelector(state => state.session);
  const { gopps } = useSelector(state => state.gopps);
  const { places: activePlaces } = useSelector(state => state.places);

  const producers = selectors.getAllProducers(gopps);
  const localeDoc = selectors.getCurrentLocaleDocument();

  const {
    searchFilter,
    quickFilter
  } = buildFilters({ producers });

  const defaultQuery = quickFilter.valueMapping(quickFilter.defaultValue);

  const [items, setItems] = useState([]);
  const [count, setCount] = useState([]);
  const [page, setPage] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(5);
  const [currentQuery, setCurrentQuery] = useState(defaultQuery);
  const [loading, setLoading] = useState(true);
  const [queue, setQueue] = useState([]);
  const [orderTables, setOrderTables] = useState([]);
  const [availablePlaces, setAvailablePlaces] = useState(activePlaces);

  const createAlert = arg => dispatch(createAlertBase(arg))

  const loadData = () => refreshItems({
    createAlert,
    currentQuery,
    docsPerPage,
    globalorder_id,
    gopps,
    locale,
    page,
    setCount,
    setItems,
    setLoading,
    addComment,
    markArrived,
    unmarkArrived,
    isPartner: localeDoc.partner,
    availablePlaces,
  })

  const loadQueueData = () => {
    if (activePlaces.length === 0) return;
    dispatch(loadPlaces())
    refreshQueue({
      globalorder_id,
      locale,
      localeDoc,
      activePlaces,
      createAlert,
      setQueue,
      setOrderTables,
      setAvailablePlaces
    })
  }

  const commonParams = {
    globalorder_id,
    locale,
    createAlert,
    loadData,
    loadQueueData
  }

  const addComment = addCommentBase({
    createAlert,
    loadData
  });
  const markArrived = markArrivedBase(commonParams);
  const unmarkArrived = unmarkArrivedBase(commonParams)
  const removeOrderFromQueue = removeOrderFromQueueBase({ createAlert, loadQueueData, loadData })

  const updateQuery = update => {
    setCurrentQuery({
      ...currentQuery,
      ...(update || {})
    })
    setPage(1)
  }

  const reloadGopps = () => {
    dispatch(fetchGoppsSuccess([]))
    dispatch(ensureGopps())
  }

  let handleToggleTable = toggleActive({ locale, createAlert, loadQueueData })

  useEffect(loadQueueData, [init, globalorder_id, locale])
  useEffect(loadData, [currentQuery, page, globalorder_id, gopps, locale, docsPerPage, init, availablePlaces])
  useEffect(() => { setCurrentQuery(defaultQuery) }, [])

  useInterval(loadData, DEFAULT_REFRESH_PERIOD_MS)
  useInterval(loadQueueData, QUEUE_REFRESH_PERIOD_MS)

  return (
    <Container fluid>

      <AccueilQueue
        className="mb-3"
        removeOrderFromQueue={removeOrderFromQueue}
        queue={queue}
        orderTables={orderTables}
        activeTables={activePlaces}
        handleToggleTable={handleToggleTable}
        reloadGopps={reloadGopps}
        tables={localeDoc.tables}
      />

      <Datatable
        columns={tableColumns}
        count={count}
        currentPage={page}
        DatatableRow={AccueilRow}
        docsPerPage={docsPerPage}
        items={items}
        loading={loading || init}
        quickFilter={quickFilter}
        searchFilter={searchFilter}
        showPagination={true}
        tableName={`accueil`}
        updateDocsPerPage={setDocsPerPage}
        updatePage={setPage}
        updateQuery={updateQuery}
        useSelector={useSelector}
        dispatch={dispatch}
      />

    </Container>
  )
}

export const Accueil = () => (
  <ErrorBoundary>
    <EnsureGlobalOrderWrapper>
      <AccueilBase />
    </EnsureGlobalOrderWrapper>
  </ErrorBoundary>
)