import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const sendTemperatures = ({
    isMobile,
    locale,
    isPartner,
    temperatures,
    createAlert,
    temperatureRecorders
}) => {

    if (!isMobile || !temperatureRecorders || temperatureRecorders.length === 0 || !locale || !isPartner) {
        return;
    }

    const serials = temperatureRecorders.map(r => r.serial);

    const data = temperatures
        .filter(temperatureRecord => serials.includes(temperatureRecord.id))
        .map(temperatureRecord => {
            return {
                'serial': temperatureRecord.id,
                'value': temperatureRecord.temperature,
                'health': temperatureRecord.battery
            };
        });


    const caller = wrapClientApiCall({
        url: 'temperaturerecorders/add-automatic-readings',
    });

    return caller({
        params: {
            context_key: 'locale',
            context_value: locale,
            data
        }
    })
        .catch(() => createAlert(new Error('Impossible d\'envoyer le relevé de température.')));

};