export const buildFilters = () => {

  const searchFilter = {
    keys: ['consumer_name'],
    placeholder: 'Chercher par nom',
  };

  const quickFilter = {
    keys: ['arrivedAt'],
    defaultValue: 'not-arrived',
    items: [
      { value: 'all', name: 'Toutes les commandes' },
      { value: 'arrived', name: 'Arrivé.es' },
      { value: 'not-arrived', name: 'À venir' },
    ],
    valueMapping(value){
      if(value === 'all') return {arrivedAt: undefined};
      if(value === 'arrived') return { arrivedAt: { $exists: true }};
      if(value === 'not-arrived') return { arrivedAt: { $exists: false } };
    },
  }

  return {
    searchFilter,
    quickFilter
  }
}