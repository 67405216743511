import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container';

import { Datatable } from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import statuses from '@kelbongoo/shared-methods/orders/statuses';
import { useInterval } from '@kelbongoo/shared-client-react-v2/dist/utils/CustomHooks/useInterval'

import { refreshItems } from './actions';
import { tableColumns, buildFilters } from './helpers';
import { OrdersNrRow } from './OrdersNrRow';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import selectors from '../../../selectors';

const DEFAULT_REFRESH_PERIOD_MS = 60000;


export const OrdersNrBase = () => {

  const dispatch = useDispatch();

  const { currentLocale: locale } = useSelector(state => state.locales);
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const { init } = useSelector(state => state.session);
  const { gopps } = useSelector(state => state.gopps);
  const producers = selectors.getAllProducers(gopps);

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState([]);
  const [page, setPage] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState({});

  const createAlert = arg => dispatch(createAlertBase(arg))

  const updateQuery = update => {
    setCurrentQuery({
      ...currentQuery,
      ...(update || {})
    })
    setPage(1)
  }

  const getStatusTitle = (order) => () => statuses.find(status => status.code === order.status).title;

  const loadData = () => {

    refreshItems({
      createAlert,
      currentQuery,
      docsPerPage,
      globalorder_id,
      gopps,
      locale,
      page,
      setCount,
      setItems,
      setLoading,
    })
  }

  useEffect(loadData, [currentQuery, page, globalorder_id, gopps, locale, docsPerPage, init])

  useInterval(loadData, DEFAULT_REFRESH_PERIOD_MS)

  useEffect(loadData,[])

  const { searchFilter } = buildFilters({ producers });

  return (
    <Container fluid>
      <Datatable
        columns={tableColumns}
        count={count}
        currentPage={page}
        DatatableRow={OrdersNrRow}
        docsPerPage={docsPerPage}
        items={items}
        loading={loading || init}
        searchFilter={searchFilter}
        showPagination={true}
        tableName={`accueil`}
        updateDocsPerPage={setDocsPerPage}
        updatePage={setPage}
        updateQuery={updateQuery}
        useSelector={useSelector}
        dispatch={dispatch}
      />
    </Container>
  )
}

export const OrdersNr = () => (
  <ErrorBoundary>
    <EnsureGlobalOrderWrapper>
      <OrdersNrBase />
    </EnsureGlobalOrderWrapper>
  </ErrorBoundary>
)