import { actions } from '../reducers/temperatureLevel';

const TEMPERATURE_TRESHOLD =  6;

export const refreshTemperatures = ({
  isMobile,
  setTemperatures,
  createAlert,
  temperatureRecorders,
  dispatch
}) => {
  
  if (!isMobile || !temperatureRecorders) {
    return;
  }

  const {
    fetchTemperatureLevelRequest,
    fetchTemperatureLevelSuccess,
    fetchTemperatureLevelFailed
  } = actions;

  dispatch(fetchTemperatureLevelRequest());

  const serials = temperatureRecorders.map(r => r.serial);

  window.BlueMaestroReader.updateResults(
    (res) => {
      var resFiltered = res
        .filter(temperatureRecord => serials.includes(temperatureRecord.id));
      setTemperatures(resFiltered);

      console.log('hellox')
      let areTemperaturesOK = true;
          resFiltered.forEach(temperatureRecord => {
            if (temperatureRecord.temperature > TEMPERATURE_TRESHOLD) {
              areTemperaturesOK = false;
            }
          });

      dispatch(fetchTemperatureLevelSuccess(areTemperaturesOK))
    },
    (err) => { 
      dispatch(fetchTemperatureLevelFailed(err));
      createAlert(err); 
    }
  );
};