// @ts-check
import { useState } from "react";
import { Link } from "react-router-dom";

import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// @ts-ignore There are no types for @kelbongoo/shared-client-react-v2
import { CommentsListModal } from "@kelbongoo/shared-client-react-v2/dist/components/CommentsListModal";
// @ts-ignore There are no types for @kelbongoo/shared-client-react-v2
import { ValidationButton } from "@kelbongoo/shared-client-react-v2/dist/components/ValidationButton";
// @ts-ignore There are no types for @kelbongoo/shared-client-react-v2
import { Icon } from "@kelbongoo/shared-client-react-v2/dist/components/Icon";
// @ts-ignore There are no types for @kelbongoo/shared-client-react-v2
import { TouchActivatedMethod } from "@kelbongoo/shared-client-react-v2/dist/components/TouchActivatedMethod";

import { OrderBacs } from "./OrderBacs.tsx";

/**
 * @typedef Item
 * @property {string} _id The order id.
 * @property {string} consumer_name
 * @property {string} email
 * @property {string[]} comments
 * // FIXME: Following types
 * @property {*} addComment
 * @property {*} uploadCommentImage
 * @property {*} getPicture
 * @property {*} first_order
 * @property {*} paidAt
 * @property {*} removed
 * @property {*} nondispatch
 * @property {*} arrivedAt
 * @property {*} markArrived
 * @property {*} unmarkArrived
 * @property {boolean} isMobile
 * @property {*} gopps
 * @property {*} isPartner
 * @property {*} availablePlaces
 */

/**
 * @typedef AccueilRowProps
 * @property {Item} item
 */

/**
 * @param {AccueilRowProps} props
 */
export const AccueilRow = ({ item }) => {
  const {
    _id,
    consumer_name,
    email,
    comments,
    addComment,
    uploadCommentImage,
    getPicture,
    first_order,
    paidAt,
    removed,
    nondispatch,
    arrivedAt,
    markArrived,
    unmarkArrived,
    isMobile = false,
    gopps,
    isPartner,
    availablePlaces,
  } = item;

  const accueilNondispatch = nondispatch
    ? isPartner
      ? nondispatch
      : nondispatch.filter(
          (/** @type {{ location: number; }} */ n) => n.location === 2
        )
    : [];

  const [show, setShow] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeSelectedPlace = (event) => {
    setSelectedPlace(event.target.value);
  };

  const handleValidate = () => {
    markArrived({
      table: selectedPlace.split("-")[0],
      place: selectedPlace.split("-")[1],
    });
  };

  return (
    <tr key={_id}>
      {/* Consommateurice */}
      <td>
        <>
          <Link to={`/accueil/${_id}`}>
            {consumer_name ? consumer_name : email}
            &nbsp;
            {paidAt ? (
              <Badge variant="success" className="mr-2">
                <Icon name="check" />
              </Badge>
            ) : (
              <Badge variant="danger" className="mr-2">
                <Icon name="exclamation-triangle" textRight />
                Commande à régler
              </Badge>
            )}
          </Link>
          <OrderBacs orderId={_id} />
          &nbsp;
          <>
            {comments && comments.length > 0 ? (
              <CommentsListModal
                comments={comments}
                isMobile={isMobile}
                allowVoiceComment={true}
                allowValidation={true}
                addComment={addComment}
                allowPhotos={true}
                uploadCommentImage={uploadCommentImage}
                getPicture={getPicture}
                hideAddComment={false}
                allowAddComment={true}
                triggerName="icon"
              />
            ) : (
              ""
            )}
          </>
          &nbsp;
          <>
            {first_order ? (
              <Badge variant="warning" className="mr-2">
                <Icon name="star" textRight />
                Première commande !
              </Badge>
            ) : (
              ""
            )}
          </>
        </>
      </td>

      {/* Manquants */}
      <td>
        {removed && removed.length > 0 ? (
          <Table
            size="sm"
            borderless={true}
            style={{ margin: "0" }}
            className="text-sm"
          >
            {removed.map(
              (
                /** @type {{ producerproduct_id: string; quantity: {}; }} */ item
              ) => {
                let gopp = gopps.find(
                  (/** @type {{ producerproduct_id: string; }} */ g) =>
                    g.producerproduct_id === item.producerproduct_id
                );
                if (gopp) {
                  return (
                    <tr>
                      <td>
                        <b>{gopp.product_name}</b>
                        <br />
                        <i className="text-muted">{gopp.producer_name}</i>
                        <br />
                        <i className="text-muted">{gopp.unit_display}</i>
                      </td>
                      <td width="5%">
                        <Badge variant="secondary">-{item.quantity}</Badge>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      <td>
                        <b>Inconnu</b>
                        <br />
                        <i className="text-muted">Inconnu</i>
                        <br />
                        <i className="text-muted">Inconnu</i>
                      </td>
                      <td width="5%">
                        <Badge variant="secondary">-{item.quantity}</Badge>
                      </td>
                    </tr>
                  );
                }
              }
            )}
          </Table>
        ) : (
          <>
            <i className="text-muted">-</i>
          </>
        )}
      </td>

      {/* ND */}
      {/* TODO Add validate ND function */}
      <td>
        {accueilNondispatch && accueilNondispatch.length > 0 ? (
          <Table
            size="sm"
            borderless={true}
            style={{ margin: "0" }}
            className="text-sm"
          >
            {accueilNondispatch.map(
              (
                /** @type {{ producerproduct_id: string; quantity: {}; }} */ item
              ) => {
                let gopp = gopps.find(
                  (/** @type {{ producerproduct_id: string; }} */ g) =>
                    g.producerproduct_id === item.producerproduct_id
                );
                if (gopp) {
                  return (
                    <tr>
                      <td>
                        <b>{gopp.product_name}</b>
                        <br />
                        <i className="text-muted">{gopp.producer_name}</i>
                        <br />
                        <i className="text-muted">{gopp.unit_display}</i>
                      </td>
                      <td width="5%" className="text-xl">
                        <Badge variant="warning">{item.quantity}</Badge>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr>
                      <td>
                        <b>Inconnu</b>
                        <br />
                        <i className="text-muted">Inconnu</i>
                        <br />
                        <i className="text-muted">Inconnu</i>
                      </td>
                      <td width="5%" className="text-xl">
                        <Badge variant="warning">{item.quantity}</Badge>
                      </td>
                    </tr>
                  );
                }
              }
            )}
          </Table>
        ) : (
          <>
            <i className="text-muted">-</i>
          </>
        )}
      </td>

      {/* Arrivé.e ? */}
      <td>
        {arrivedAt ? (
          <ValidationButton
            buttonIcon="check"
            buttonProps={{ variant: "link", size: "lg" }}
            buttonClasses={"text-success"}
            onValidation={unmarkArrived}
            noButtonText={true}
            mustConfirm={true}
            confirmationText="Annuler le passage&nbsp;?"
          />
        ) : (
          <>
            <TouchActivatedMethod callback={handleShow} timeout={1000}>
              <ValidationButton
                buttonIcon="clock"
                buttonProps={{ variant: "link", size: "lg" }}
                buttonClasses={"text-muted"}
                onValidation={markArrived}
                noButtonText={true}
                // mustConfirm={true}
                // confirmationText="Confirmer l'arrivée&nbsp;?"
              />
            </TouchActivatedMethod>

            <Modal show={show} onHide={handleClose}>
              {/* <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header> */}
              <Modal.Body>
                <Form.Group controlId="tablePlaceForm">
                  {/* <Form.Label>Example select</Form.Label> */}
                  <Form.Control
                    as="select"
                    onChange={handleChangeSelectedPlace}
                  >
                    <option disabled selected>
                      Choisir une place
                    </option>
                    {availablePlaces &&
                      availablePlaces.map((place) => (
                        <option value={`${place.table}-${place.place}`}>
                          Table {place.tableName}, place {place.alias}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Fermer
                </Button>
                <Button variant="primary" onClick={handleValidate}>
                  Valider
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </td>
    </tr>
  );
};
