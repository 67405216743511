import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const addComment = ({
    order_id,
    createAlert,
    setCurrentOrder,
    currentOrder,
}) => (comment) =>{

    const caller = wrapClientApiCall({
        url: 'orders/add-comment',
    });

    return caller({
        params: {
            order_id,
            comment
        }
    })
        .then(({ comments }) => {
            let currentOrderCopy = currentOrder;
            currentOrderCopy.comments = comments;
            setCurrentOrder(currentOrderCopy);
        })
        .catch(err => {
            createAlert(err)
        })
};