import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';
import { actions as productInfosActions } from '../reducers/productinfos';
import { actions as orderCountsActions } from '../reducers/ordercounts';
import { actions as tasksActions } from '../reducers/tasks';

export const getDistributionData = () => (dispatch, getState) => {

  const {
    locales: { currentLocale: locale },
    globalorderlocales: { currentGlobalOrder: globalorder_id }
  } = getState();

  const {
    fetchProductInfosRequest,
    fetchProductInfosSuccess,
    fetchProductInfosFailed
  } = productInfosActions;

  const {
    fetchOrderCountsRequest,
    fetchOrderCountsSuccess,
    fetchOrderCountsFailed
  } = orderCountsActions;

  const {
    fetchTasksRequest,
    fetchTasksSuccess,
    fetchTasksFailed
  } = tasksActions;

  const caller = wrapClientApiCall({
    url: 'globalorders/get-distribution-data'
  });

  dispatch(fetchProductInfosRequest());
  dispatch(fetchOrderCountsRequest());
  dispatch(fetchTasksRequest());

  return caller({
    params: {
      locale,
      globalorder_id
    }
  }).then(({
      tasks,
      orderCounts,
      productInfos
    }) => {
      let formattedProductInfosList = productInfos.map(productInfo => {
        let formattedProductInfo = {...productInfo};
        formattedProductInfo.title = `${productInfo.product_name}`
        formattedProductInfo.subtitle = `${productInfo.producer_name} - ${productInfo.unit_display}`
        let icon; 
        switch (productInfo.info_type) {
          case 'missing':
            icon='ban'
            break;
          case 'comments':
            icon='comment'
            break;
          default:
            icon=productInfo.info_type
            break;
        }
        formattedProductInfo.infoTypes = [{icon}]
        return formattedProductInfo;
      });
      dispatch(fetchProductInfosSuccess(formattedProductInfosList));
      dispatch(fetchOrderCountsSuccess(orderCounts));
      dispatch(fetchTasksSuccess(tasks));
    }).catch(error => {
      dispatch(fetchProductInfosFailed(error.message || error.name))
      dispatch(fetchOrderCountsFailed(error.message || error.name))
      dispatch(fetchTasksFailed(error.message || error.name))
    });
};