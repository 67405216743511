import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container'
import Badge from 'react-bootstrap/Button';

import { createAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';

import { BoutiqueOrdersDetail } from '@kelbongoo/shared-client-react-v2/dist/components/BoutiqueOrdersDetail';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';

import selectors from '../../../selectors';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import {
    fetchDetail,
    boutiqueproducerproductsFetch,
    producersGetAllNames,
    cancelLocalePayment,
    addLocalePayment
} from './actions';

import { getAmountPaid, printReceipt } from './helpers';

/**
 * 
 * @returns a react component showing order details
 */
export const BoutiqueOrderDetailBase = () => {

    const { boutiqueorder_id } = useParams();

    const isMobile = !!window.cordova;

    const dispatch = useDispatch();

    const { currentLocale: locale } = useSelector(state => state.locales);
    const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
    const localeDocument = selectors.getCurrentLocaleDocument();

    const [loading, setLoading] = useState(true);
    const [currentOrder, setCurrentOrder] = useState();
    const [items, setItems] = useState([])
    const [reduced, setReduced] = useState([])
    const [consumerId, setConsumerId] = useState()


    const loadData = () => {
        fetchDetail({
            createAlert: arg => dispatch(createAlert(arg)),
            boutiqueorder_id,
            setLoading,
            setCurrentOrder,
            setItems,
            setReduced,
            setConsumerId
        })
    }

    useEffect(loadData, [dispatch, boutiqueorder_id]);

    useEffect(() => {
        if (isMobile) {
            if (!window.plugin.PosPrinter || !window.plugin.PosPrinter.printFormattedText) {
                console.warn('Unable to launch PosPrinter');
            } else {
                console.info('PosPrinter plugin launched');
            }
        }
    });

    return (
        <Container className='px-5' fluid>

            {loading || !currentOrder ?
                <LoadingSpinner />
                :
                <>
                    <Link to={`/caisse`} >
                        <Badge className='mb-2' variant='outline-secondary'>
                            <Icon name='arrow-left' textRight /> Précédent
                        </Badge>
                    </Link>

                    <BoutiqueOrdersDetail
                        /**shared props**/
                        boutiqueorder={currentOrder}
                        items={items}
                        reduced={reduced}

                        /** payment params*/
                        allowAddPayment={!currentOrder.paidAt}
                        getAmountPaid={getAmountPaid}
                        printReceipt={printReceipt({
                            isMobile,
                            createAlert,
                            currentOrder,
                            localeDocument
                        })}
                        showPrintReceipt={isMobile}
                        addPayment={addLocalePayment({ boutiqueorder_id, globalorder_id, locale, createAlert, consumerId, refreshData: loadData })}
                        consumerId={consumerId}
                        // consumerCredits=''
                        showCancelPayment={true}
                        cancelPayment={cancelLocalePayment({globalorder_id, locale, boutiqueorder_id , refreshData: loadData, createAlert})}

                        /**add item params */
                        runSearch={boutiqueproducerproductsFetch({ locale, createAlert })}
                        addItem={() => { }} // TODO : if order not paid we'd like to add items
                        // setItem=''
                        setItems={setItems}
                        setReduced={setReduced}
                        runExtraSearch={producersGetAllNames(createAlert)}
                        // extraOptions=''
                        // extraPlaceholderText=''
                        // placeholderText=''

                        /**consumer search params */ // TODO
                        setConsumerId={setConsumerId}
                        // setConsumerSuggestions=''
                        // fetchConsumerCredit=''
                        // setConsumerCredits=''
                        // setConsumer=''
                        // runConsumerSearch=''
                        // consumerSuggestions=''

                        /**comments params */
                        allowAddComment={false}
                        // addComment='' // TODO

                        showClearOrder={false}

                        /** upper left params */
                        Link={Link}
                        noConsumerLink={true}
                        showOrderBoutique={true}

                        showBackButton={false}

                    />
                </>
            }

        </Container>
    )
}

export const BoutiqueOrderDetail = () => (
    <ErrorBoundary>
        <EnsureGlobalOrderWrapper>
            <BoutiqueOrderDetailBase />
        </EnsureGlobalOrderWrapper>
    </ErrorBoundary>
)