import Badge from "react-bootstrap/Badge";
import styles from "./NdProductItem.module.css";

function NdBadge({ nd }) {
  return (
    <Badge
      variant={
        nd.location === 0
          ? "primary"
          : nd.location === 1
          ? "success"
          : nd.location === 2
          ? "warning"
          : "danger"
      }
      className="mx-1"
    >
      {nd.quantity}
    </Badge>
  );
}

/**
 * @typedef NdItem
 * @property {string=} deliveredAt used to know if the product's been given
 * @property {string} product_name
 * @property {string} producer_name
 * @property {string} unit_display
 * @property {string} _id
 * @property {0|1|2|3} location
 */

/**
 * @typedef NdProductItemProps
 * @property {NdItem} nd
 * @param {NdProductItemProps} props
 */
export function NdProductItem({
  nd,
  order,
  undoNondispatchDelivery,
  markNondispatchDelivered,
}) {
  return (
    <div
      className={styles.NdProductItem}
      key={`${order._id}-${nd.product_name}`}
      onClick={
        nd.deliveredAt
          ? undoNondispatchDelivery(nd._id)
          : markNondispatchDelivered(nd._id)
      }
    >
      <span className={styles.Product}>{nd.product_name}&nbsp;-&nbsp;</span>
      <span className={styles.Producer}>{nd.producer_name}</span>
      
      <Badge variant="secondary" className="mx-1">
        {nd.unit_display}
      </Badge>
      {nd.deliveredAt ? (
        <NdBadge nd={nd} />
      ) : (
        <span className="text-xl">
          <NdBadge nd={nd} />
        </span>
      )}
    </div>
  );
}
