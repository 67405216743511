import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const boutiqueproducerproductsFetch = ({
    locale,
    createAlert
}) => ({ search, extra }) => {

    const caller = wrapClientApiCall({
        url: 'boutiqueproducerproducts/fetch',
    });

    const params = {
        boutique: locale,
        product_name: search,
        // producerproduct_id: { $nin: usedProducerProductIds },
        producer_id: extra
    }

    const fields = { producer_name: 1, product_name: 1, unit_display: 1, producerproduct_id: 1, consumer_price: 1, tva: 1 }

    const options = {
        fields,
        sort: { product_name: 1 },
        limit: 8
    }

    return caller({ params, options })
        .then(({ items }) => { return items.map(item => ({ ...item, _id: item.producerproduct_id })) })
        .catch(err => { createAlert(err) })
};