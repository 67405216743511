import { createTaskInstanceGroupEvent } from '@kelbongoo/shared-methods/taskinstances/collection';

export const formatEvents = ({ tasks, orderCounts }) => {
  const output = [
    {
      event_type: 'distribution',
      title: 'Commandes internet',
      isDone: (orderCounts.nr_total + orderCounts.departed) === orderCounts.total,
      hasProgress: true,
      progressPct: Math.ceil(( (orderCounts.nr_total + orderCounts.departed) / (orderCounts.total || 1) ) * 100),
      hideDoneInfo: true
    },
    {
      event_type: 'validation',
      title: 'Commandes NR',
      isDone: orderCounts.nr_validated === orderCounts.nr_total,
      hasProgress: true,
      progressPct: Math.ceil((orderCounts.nr_validated/orderCounts.nr_total || 1) * 100),
      hideDoneInfo: true
    }
  ];

  if(tasks && tasks.length > 0){
    output.push(
			createTaskInstanceGroupEvent(tasks)
		);
  }
  
  return output;
};