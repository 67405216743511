import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const removeOrderFromQueue = ({
  createAlert,
  loadData,
    loadQueueData
}) => (order_id) => () => {

  const caller = wrapClientApiCall({
    url: 'orders/undo-arrival',
  })

  return caller({
    params: {
      order_id
    },
  })
  .then(() => {
    loadData()
    loadQueueData()
  })
    .catch((err) => createAlert(err));
};