import { getRoleFromPath } from './getRoleFromPath';


export const getRoleName = (path) => {

  const role = getRoleFromPath(path);

  if(!role){
    return 'Home';
  }

  const output = {
    'accueil': 'Accueil',
    'caisse': 'Caisse',
    'couloir': 'Couloirs',
    'table': 'Tables',
    'orders-nr': 'Commandes NR',
    'temperatures': 'Températures',
    'tasks': 'Tâches'
  }[role];

  return output || 'Home';
}