import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Dropdown from 'react-bootstrap/Dropdown';
import Alert from 'react-bootstrap/Alert'

import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon'

export const CouloirHeader = ({
    currentCouloir,
    currentMaxBacs,
    showDeliveryTable,
    setCurrentCouloir,
    setCurrentMaxBacs,
    setShowDeliveryTable,
    handleRefreshClick
}) => {

    const handleCouloirClick = (couloir) => () => setCurrentCouloir(couloir)
    const handleMaxBacsClick = (maxBacs) => () => setCurrentMaxBacs(maxBacs)
    const handleShowDeliveryClick = (bool) => () => setShowDeliveryTable(bool)

    return (
        <Alert variant={currentCouloir === 'frais' ? 'primary' : 'success'}>
            <h2>
                {currentMaxBacs} bacs du couloir {currentCouloir}
            </h2>
            <ButtonToolbar aria-label="Configuration interface couloir">

                <ButtonGroup size='lg' className="mr-2" aria-label="Actualisation">
                    <Button variant="secondary" onClick={handleRefreshClick}>
                        <Icon name='sync'/>
                    </Button>
                </ButtonGroup>

                <ButtonGroup size='lg' className="mr-2" aria-label="Choix du couloir">
                    <Button variant="primary" onClick={handleCouloirClick('frais')} disabled={currentCouloir === 'frais'}>
                        <Icon name='snowflake' textRight /> Frais
                </Button>
                    <Button variant="success" onClick={handleCouloirClick('sec')} disabled={currentCouloir === 'sec'}>
                        <Icon name='carrot' textRight /> Sec
                </Button>
                </ButtonGroup>

                <Dropdown
                    as={ButtonGroup}
                    id={`dropdown-maxbacs`}
                    size="lg"
                    title="Nombre de bacs affichés"
                    drop='right'
                    className="mr-2"
                >
                    <Dropdown.Toggle variant='secondary'>
                        {currentMaxBacs}
                </Dropdown.Toggle>
                    <Dropdown.Menu >
                        <Dropdown.Item key={3} onClick={handleMaxBacsClick(3)} active={currentMaxBacs === 3}>3</Dropdown.Item>
                        <Dropdown.Item key={4}onClick={handleMaxBacsClick(4)} active={currentMaxBacs === 4}>4</Dropdown.Item>
                        <Dropdown.Item key={5} onClick={handleMaxBacsClick(5)} active={currentMaxBacs === 5}>5</Dropdown.Item>
                        <Dropdown.Item key={6} onClick={handleMaxBacsClick(6)} active={currentMaxBacs === 6}>6</Dropdown.Item>
                        <Dropdown.Item key={7} onClick={handleMaxBacsClick(7)} active={currentMaxBacs === 7}>7</Dropdown.Item>
                        <Dropdown.Item key={8} onClick={handleMaxBacsClick(8)} active={currentMaxBacs === 8}>8</Dropdown.Item>
                        <Dropdown.Item key={9} onClick={handleMaxBacsClick(9)} active={currentMaxBacs === 9}>9</Dropdown.Item>
                        <Dropdown.Item key={10} onClick={handleMaxBacsClick(10)} active={currentMaxBacs === 10}>10</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <ButtonGroup size='lg' className="mr-2" aria-label="Actualisation">
                    <Button variant="secondary" onClick={handleShowDeliveryClick(!showDeliveryTable)}>
                        <Icon name={showDeliveryTable ? 'check-square' : 'square'} textRight/> 
                        Tables
                    </Button>
                </ButtonGroup>

            </ButtonToolbar>
        </Alert>
    );
}