export const tableColumns = [
  {
    title: 'Consommateur.ice',
  },
  {
    title: 'Manquants',
    classes: 'vertical-center',
    width: '40%',
  },
  {
    title: 'Non Dispatch',
    classes: 'vertical-center',
    width: '40%',
  },
  {
    title: '',
    classes: 'text-center vertical-center',
  }
]