import { DefaultForm } from '@kelbongoo/shared-client-react-v2/dist/components/DefaultForm';

export const ModalBody = ({
    closeModal, // inherited
    updateNrBacs,
    currentOrder,
    availableNRBacs
}) => {

    const onValidation = (data) => {
        updateNrBacs(data)
            .finally(closeModal())
    }
    return (
        // TODO create multi select input
        <DefaultForm
            onValidation={onValidation}
            formId={currentOrder.id}
            elements={[

                {
                    key: 'nr_bacs',
                    title: 'Bacs',
                    column: 1,
                    component: 'tags_input'
                },
                // {
                //     key: 'nr_bacs',
                //     title: 'Bacs',
                //     type: 'number',
                //     dataType: 'number',
                //     size: 'lg',
                //     inputClasses: 'text-center',
                //     inputProps: { size: 'lg' },
                //     column: 1,
                //     component: 'select',
                //     value: 0,
                //     placeholderOption: 'Choisir un bac NR...',
                //     options: availableNRBacs.map(bac => ({ name: bac, value: bac })),
                //     required: true,
                // },
            ]}
            groups={[]}
            allowValidation={true}
            formClasses='mb-5'

        />
    )
}