import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const cancelLocalePayment = ({
    globalorder_id,
    locale,
    createAlert,
    boutiqueorder_id,
    refreshData,
}) => () => {

    const caller = wrapClientApiCall({
        url: 'globalorderlocales/cancel-locale-payment',
    });

    return caller({
        params: {
            globalorder_id,
            locale,
            boutiqueorder_id,
            userId: '2W8X2SaMhytesy4ne'
          }
    })
        .then(() =>refreshData())
        .catch(err => {
            createAlert(err)
        })
};