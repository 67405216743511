import React, { useEffect, useState } from 'react';
import { BrowserRouter, HashRouter, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ProtectedRoute } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { NotFound } from '@kelbongoo/shared-client-react-v2/dist/components/NotFound';
import { ErrorPage } from '@kelbongoo/shared-client-react-v2/dist/components/ErrorPage';
import { fetchLocales, refreshGlobalOrderLocales } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase/actions';
import { useInterval } from '@kelbongoo/shared-client-react-v2/dist/utils/CustomHooks/useInterval';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';

import { getTemperatureRecorders } from './actions/getTemperatureRecorders';
import { refreshTemperatures } from './actions/refreshTemperatures';
import { sendTemperatures } from './actions/sendTemperatures';
import { boutiquedaysFetch } from './actions/boutiquedaysFetch';
import { loadPlaces } from './actions/loadPlaces';


import { Login } from './components/pages/Login';
import { ForgotPassword } from './components/pages/ForgotPassword';
import { Header } from './components/ui/Header';
import { Home } from './components/pages/Home';
import { ChooseGlobalOrder } from './components/pages/ChooseGlobalOrder';
import { NoGlobalOrders } from './components/pages/NoGlobalOrders';
import { Tasks } from './components/pages/Tasks';
import { Accueil } from './components/pages/Accueil';
import { Caisse } from './components/pages/Caisse';
import { OrderDetail } from './components/pages/OrderDetail';
import { BoutiqueOrderDetail } from './components/pages/BoutiqueOrderDetail';
import { Table } from './components/pages/Table';
import { Couloir } from './components/pages/Couloir';
import { OrdersNr } from './components/pages/OrdersNr';
import { OrderNrDetail } from './components/pages/OrderNrDetail';
import { Temperatures } from './components/pages/Temperatures';

import selectors from './selectors';
import './icons';
import './App.css';

const GLOBALORDER_REFRESH_INTERVAL = 5 * 60 * 1000; // 5 minutes
const TEMPERATURE_REFRESH_INTERVAL_MS = 60 * 1000; //1''
const TEMPERATURE_SEND_INTERVAL_MS = 15 * 60 *1000; //15''

function App() {

  let Router;
  if (window.cordova) {
    Router = HashRouter;
  } else {
    Router = BrowserRouter;
  }

  const isMobile = !!window.cordova;

  const dispatch = useDispatch();
  const { loggedIn, isVerifying } = useSelector(state => state.currentUser);
  const { currentLocale } = useSelector(state => state.locales);
  const { currentGlobalOrder } = useSelector(state => state.globalorderlocales);

  const [temperatures, setTemperatures] = useState([]);
  const { list: temperatureRecorders } = useSelector(state => state.temperatureRecorders);
  const createAlert = arg => dispatch(createAlertBase(arg))
  const localeDocument = selectors.getCurrentLocaleDocument();

  useEffect(() => {
    dispatch(fetchLocales());
  }, [dispatch])

  useEffect(() => {

    if (!loggedIn || !currentLocale || isVerifying) {
      return;
    }

    dispatch(refreshGlobalOrderLocales('distribution'));

    if (isMobile) dispatch(getTemperatureRecorders());

    if (!localeDocument.partner) {
      dispatch(boutiquedaysFetch());
      dispatch(loadPlaces());
    }

    const interval = setInterval(() => {
      dispatch(refreshGlobalOrderLocales('distribution'));
    }, GLOBALORDER_REFRESH_INTERVAL);

    return () => clearInterval(interval);

  }, [loggedIn, currentLocale, currentGlobalOrder, isVerifying, dispatch]);

  useInterval(() => {
    if (!isMobile) return;
    refreshTemperatures({
      isMobile,
      setTemperatures,
      temperatureRecorders,
      createAlert,
      dispatch
    });
  }, TEMPERATURE_REFRESH_INTERVAL_MS)

  useInterval(() => {
    if (!isMobile) return;
    sendTemperatures({
      isMobile,
      locale: currentLocale,
      isPartner: localeDocument.partner,
      temperatures,
      temperatureRecorders,
      createAlert
    });
  }, TEMPERATURE_SEND_INTERVAL_MS)

  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/forgotpassword' component={ForgotPassword} />
        <ProtectedRoute exact path='/' component={Home} />
        <ProtectedRoute exact path='/choose-globalorder' component={ChooseGlobalOrder} />
        <ProtectedRoute exact path='/no-globalorders' component={NoGlobalOrders} />
        <ProtectedRoute exact path='/error' component={ErrorPage} />
        <Route exact path='/not-found' component={NotFound} />

        <ProtectedRoute exact path='/accueil' component={Accueil} />

        <ProtectedRoute exact path='/caisse' component={Caisse} />
        <ProtectedRoute exact path='/caisse/orders/:order_id' component={OrderDetail} />
        <ProtectedRoute exact path='/accueil/:order_id' component={OrderDetail} />
        <ProtectedRoute exact path='/caisse/boutiqueorders/:boutiqueorder_id' component={BoutiqueOrderDetail} />

        <ProtectedRoute exact path='/couloir' component={Couloir} />

        <ProtectedRoute exact path='/table' component={Table} />

        <ProtectedRoute exact path='/orders-nr' component={OrdersNr} />
        <ProtectedRoute exact path='/orders-nr/:order_id' component={OrderNrDetail} />

        <ProtectedRoute exact path='/tasks' component={Tasks} />

        {isMobile && <ProtectedRoute exact path='/temperatures' component={Temperatures} />}

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
