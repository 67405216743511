import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Alert from 'react-bootstrap/Alert'

import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon'

export const CaisseHeader = ({
    currentPage,
    setCurrentPage,
}) => {

    const handleCurrentPageClick = (page) => () => setCurrentPage(page)

    return (
        <Alert>
            <ButtonToolbar className='d-flex justify-content-left' aria-label="Configuration interface couloir">

                <ButtonGroup size='lg' className="mr-2" aria-label="Choix de la page de Caisse">
                    <Button onClick={handleCurrentPageClick('orders')} disabled={currentPage === 'orders'}>
                        <Icon name='people-carry' textRight />
                        {/* {!(currentPage === 'orders') ? 'Commandes' : ''} */}
                    </Button>
                    <Button onClick={handleCurrentPageClick('boutiqueorders')} disabled={currentPage === 'boutiqueorders'}>
                        <Icon name='shopping-cart' textRight />
                        {/* {!(currentPage === 'boutiqueorders') ? ' Ventes directes' : ''} */}
                    </Button>
                    <Button onClick={handleCurrentPageClick('boutiqueordersnew')} disabled={currentPage === 'boutiqueordersnew'}>
                        <Icon name='cart-plus' textRight />
                        {/* {!(currentPage === 'boutiqueordersnew') ? 'Nouvelle vente directe' : ''} */}
                    </Button>
                </ButtonGroup>

            </ButtonToolbar>
        </Alert>
    );
}