import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Alert from 'react-bootstrap/Alert'

import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon'

export const TemperaturesHeader = ({
    handleRefreshClick,
    refreshing
}) => {

    return (
        <Alert variant='light'>
            <ButtonToolbar aria-label="Temperature header">

                <ButtonGroup size='lg' className="mr-2" aria-label="Actualisation">
                    <Button variant="secondary" onClick={handleRefreshClick} disabled={refreshing}>
                        <Icon name='sync'/>
                    </Button>
                </ButtonGroup>

            </ButtonToolbar>
        </Alert>
    );
}