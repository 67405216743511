import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';

import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon'
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner'
import { useInterval } from '@kelbongoo/shared-client-react-v2/dist/utils/CustomHooks/useInterval';

import { ErrorBoundary } from '../../ui/ErrorBoundary';
import selectors from '../../../selectors';
import { TemperaturesHeader } from './TemperaturesHeader'
import { updateResults } from './actions';
import { rssiText, batteryClass, temperatureClass } from './helpers';

const DEFAULT_REFRESH_PERIOD_MS = 60000;

export const TemperaturesBase = () => {

  const isMobile = !!window.cordova;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [backgroundLoading, setBackgroundLoading] = useState(false);
  const [temperatures, setTemperatures] = useState([]);

  const localeDocument = selectors.getCurrentLocaleDocument();
  const { list: temperatureRecorders, loading: temperatureRecordersLoading } = useSelector(state => state.temperatureRecorders);

  const createAlert = arg => dispatch(createAlertBase(arg))

  useEffect(() => {
    updateResults(
      {
        localeDocument,
        setBackgroundLoading,
        setTemperatures,
        setLoading,
        createAlert,
        temperatureRecorders,
        isPartner: localeDocument.partner
      }
    )
  }, [temperatureRecorders, localeDocument]);

  useInterval(() => {
    updateResults(
      {
        localeDocument,
        setBackgroundLoading,
        setTemperatures,
        setLoading,
        createAlert,
        temperatureRecorders,
        isPartner: localeDocument.partner
      }
    )
  }, DEFAULT_REFRESH_PERIOD_MS);

  const handleRefreshClick = () => updateResults(
    {
      localeDocument,
      setBackgroundLoading,
      setTemperatures,
      setLoading,
      createAlert,
      temperatureRecorders,
      isPartner: localeDocument.partner
    }
  );

  const refreshing = loading || backgroundLoading || temperatureRecordersLoading;

  return (
    <>

      {!isMobile ?

        <>

          {loading ?
            <LoadingSpinner />
            :
            <Container fluid>

              <TemperaturesHeader
                handleRefreshClick={handleRefreshClick}
                refreshing={refreshing}
              />

              <Table bordered={true}>
                <thead>
                  <tr>
                    <th scope="col">Appareil</th>
                    <th scope="col" style={{ width: '50px' }}>Batterie</th>
                    <th scope="col" style={{ width: '100px' }}>Température</th>
                  </tr>
                </thead>
                <tbody>
                  {temperatures.map(temperature => (
                    <tr key={temperature.id}>
                      <td>
                        {temperature.id}
                        <span className='text-muted'>
                          <Icon name='signal' textRight /> {rssiText(temperature.rssi)}
                        </span>
                      </td>
                      <td className={`text-center ${batteryClass(temperature.battery)}`}>
                        {temperature.battery}&nbsp;%
                      </td>
                      <td className={`text-center ${temperatureClass(temperature.temperature)}`}>
                        {temperature.temperature}&nbsp;°C
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

            </Container>
          }
        </>
        :
        <Row className='text-center'>
          <Col xs="12" sm="12" md="12" lg="12" xl="12">
            <Image src='http://cdn.kelbongoo.com/images/banane-sad.svg' alt='banane-sad' fluid />
            <h4>Module indisponible</h4>
          </Col>
        </Row>
      }
    </>
  )
}

export const Temperatures = props => (
  <ErrorBoundary>
    <EnsureGlobalOrderWrapper>
      <TemperaturesBase {...props} />
    </EnsureGlobalOrderWrapper>
  </ErrorBoundary>
)