import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { formatOrder } from "../helpers";

export const updateItem = ({
    createAlert,
    order_id,
    setCurrentOrder
}) => (params) => {

    params.list = params.listName;
    params.isConsumer = false;

    if (params.action === 'remove') {
        params.reason = 2; // removed during distribution
    }

    // WARN It is done in API
    // if (
    //   !Orders.validateUpdateItemParams({ order, params, isConsumer: false })
    // ) {
    //   return handleResponse(new Error('Invalid update parameters'));
    // }

    const caller = wrapClientApiCall({
        url: 'orders/update-item',
    });

    return caller({
        params: {
            order_id,
            params
        }
    })
        .then(({ updatedDoc }) => { setCurrentOrder(formatOrder(updatedDoc)) })
        .catch(err => { createAlert(err) })
};