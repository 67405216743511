import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const refreshData = ({
    createAlert,
    locale,
    globalorder_id,
    setOrders,
    setPlaces,
    setLoading
}) => {
    setLoading(true);

    const caller = wrapClientApiCall({
        url: 'globalorderlocales/get-distribution-tables',
    })

    return caller({
        params: {
            locale: locale,
            globalorder_id: globalorder_id,
        },
    })
        .then(({ orders, places }) => {
            setOrders(orders);
            setPlaces(places);
            setLoading(false);
        })
        .catch((err) => {
            createAlert(err)
            setLoading(false);
        });
};