import React, { useEffect, useState } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';

import { createAlert } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';

import { ValidationButton } from '@kelbongoo/shared-client-react-v2/dist/components/ValidationButton';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { Icon } from '@kelbongoo/shared-client-react-v2/dist/components/Icon';
import { DetailUpperRight } from '@kelbongoo/shared-client-react-v2/dist/components/DetailUpperRight';
import { ModalLink } from '@kelbongoo/shared-client-react-v2/dist/components/ModalLink';
import statuses, { reduce } from '@kelbongoo/shared-methods/orders/statuses';
import { run as getEventsListFunc } from '@kelbongoo/shared-methods/utils/getEventsList';

import { ErrorBoundary } from '../../ui/ErrorBoundary';
import { OrderNrDetailItem } from './OrderNrDetailItem';
import { ModalBody } from './ModalBody';
import { EVENTS_MAP } from '../../../helpers/events';

import {
    addComment as addCommentBase,
    fetchDetail,
    updateNrBacs as updateNrBacsBase,
    validateOrder,
} from './actions';

import {
    formatProducerProductIds,
    formatItems
} from './helpers';
import { Order } from '../../../models/Order';

/**
 * 
 * @returns a react component showing order details
 */
export const OrderNrDetailBase = () => {

    const { order_id } = useParams();

    const isMobile = !!window.cordova;

    const dispatch = useDispatch();

    const { currentLocale: locale } = useSelector(state => state.locales);
    const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);

    const [loading, setLoading] = useState(true);
    const [running, setRunning] = useState(false);
    const [validated, setValidated] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [formattedItems, setFormattedItems] = useState([]);
    const [items, setItems] = useState([]);
    const [reduced, setReduced] = useState([]);
    const [removed, setRemoved] = useState([]);
    const [currentOrder, setCurrentOrder] = useState([]);
    const [availableNRBacs, setAvailableNRBacs] = useState([]);
    const [nrBacs, setNrBacs] = useState(currentOrder.nr_bacs);

    const formattedCurrentOrder = new Order(currentOrder);

    const allowValidation = () => {
        let { producerproductIds } = formatProducerProductIds(({ items, reduced, removed }));
        return checkedItems.length === producerproductIds.length;
    }

    const onValidation = () => {
        setRunning(true);
        return validateOrder({
            globalorder_id,
            order_id,
            items,
            reduced,
            removed,
            currentOrder,
            checkedItems,
            createAlert,
            setValidated
        })
            .finally(() => setRunning(false))
    }

    const addComment = addCommentBase({ order_id: currentOrder.id, createAlert, setCurrentOrder, currentOrder })
    const updateNrBacs = updateNrBacsBase({ order_id, currentOrder, setCurrentOrder, createAlert, setNrBacs })

    const allowUpdateNrBacs = true;

    const loadData = () => {
        fetchDetail({
            order_id,
            setLoading,
            createAlert: arg => dispatch(createAlert(arg)),
            setCurrentOrder,
            globalorder_id,
            locale,
            setAvailableNRBacs,
            setNrBacs,
            setItems,
            setReduced,
            setRemoved
        })
    }

    useEffect(loadData, [dispatch, order_id, globalorder_id, locale]);

    useEffect(() => {
        let fi = formatItems({
            currentOrder: formattedCurrentOrder,
            checkedItems,
            setCheckedItems,
            items,
            setItems,
            reduced,
            setReduced,
            removed,
            setRemoved,
        });
        setFormattedItems(fi);
    }, [items, reduced, removed, checkedItems])

    const commentsProps = {
        addComment: addComment,
        // allowAddComment: true && currentOrder.allowAddComment(),
        allowAddComment: false,
        allowPhotoComments: false,
        comments: currentOrder.comments,
        // getPicture: props.getCommentPicture,
        hideAddComment: false,
        isMobile: isMobile,
        triggerName: 'button',
        showComments: true,
        // uploadCommentImage: props.uploadCommentImage
    }

    if (validated) {
        return (<Redirect to={`/orders-nr`} />);
    }

    return (
        <>

            {loading || !currentOrder ?
                <LoadingSpinner />
                :
                <Container className='px-5' fluid>

                    <Row>
                        <Col xs='3'>

                            <Link to='/orders-nr' title='back-link' className='btn btn-light mb-2 mr-2'>
                                <Icon name='arrow-left' />
                            </Link>

                            <div className='text-xl'>
                                <b>{currentOrder.consumer_name}</b>
                            </div>
                            <div className='mb-2'>
                                {currentOrder.consumer_email}
                                {currentOrder.consumer_telephone && <div>{currentOrder.consumer_telephone}</div>}
                            </div>


                            {currentOrder.bacs_frais && currentOrder.bacs_frais.length > 0 && (
                                <div data-testid='frais-bacs' key='bacs-frais' className='text-center'>
                                    {currentOrder.bacs_frais.map(b => (
                                        <h1><Badge variant='primary' className='mr-2'>{b}</Badge></h1>
                                    ))}
                                </div>
                            )}

                            {currentOrder.bacs_sec && currentOrder.bacs_sec.length > 0 && (
                                <div data-testid='sec-bacs' key='bacs-sec' className='text-center'>
                                    {currentOrder.bacs_sec.map(b => (
                                        <h1><Badge variant='success' className='mr-2'>{b}</Badge></h1>

                                    ))}
                                </div>
                            )}


                            {currentOrder.nr_bacs && currentOrder.nr_bacs.length > 0 && (
                                <div data-testid='nr-bacs' key='bacs-nr' className='text-center mb-2'>
                                    {currentOrder.nr_bacs.map(b => (
                                        <h1 className='d-inline'><Badge variant='danger' className='mr-2'>{b}</Badge></h1>
                                    ))}
                                </div>
                            )}

                            {allowUpdateNrBacs &&
                                <div className='text-center'>

                                    <ModalLink
                                        linkClasses='btn btn-lg btn-light mb-2 '
                                        // linkStyle=''
                                        linkIcon='sync'
                                        // linkIconClasss=''
                                        linkText='Bacs NR'
                                        modalHeaderTitle='Mettre à jour les bacs NR'
                                    >
                                        <ModalBody
                                            updateNrBacs={updateNrBacs}
                                            currentOrder={currentOrder}
                                            availableNRBacs={availableNRBacs}
                                        />

                                    </ModalLink>
                                </div>
                            }

                        </Col>

                        <Col xs='9' className='text-right'>
                            <div>
                                <DetailUpperRight
                                    statusName={formattedCurrentOrder && formattedCurrentOrder.status && formattedCurrentOrder.getStatusTitle()}
                                    eventsList={formattedCurrentOrder && formattedCurrentOrder.getEventsList()}
                                    commentsProps={commentsProps}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs='12'>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Produit</th>
                                        <th className='text-center'>Quantit&eacute;</th>
                                        {!currentOrder.nrValidatedAt &&
                                            <th className='text-center'>Valid&eacute;</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {formattedItems.map(item => {
                                        return (
                                            <OrderNrDetailItem
                                                {...item}
                                            />

                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    {!currentOrder.nrValidatedAt &&
                        <Row>
                            <Col xs='12'>

                                <ValidationButton
                                    buttonClasses='btn-primary btn-block mb-5'
                                    enabled={allowValidation}
                                    onValidation={onValidation}
                                    running={running}
                                    mustConfirm={true}
                                    confirmationText='Etes-vous sûr de vouloir valider cette commande ?'
                                />

                            </Col>
                        </Row>
                    }

                </Container>
            }

        </>
    )
}

export const OrderNrDetail = () => (
    <ErrorBoundary>
        <EnsureGlobalOrderWrapper>
            <OrderNrDetailBase />
        </EnsureGlobalOrderWrapper>
    </ErrorBoundary>
)