import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";


export const toggleActive = ({
  locale,
  createAlert,
  loadQueueData
}) => (table, place) => () => {

  const caller = wrapClientApiCall({
    url: 'localeplaces/toggle-active',
  })

  return caller({
    params: {
      locale,
      table,
      place,
    },
  })
  .then(() => {
    loadQueueData()
  })
    .catch((err) => createAlert(err));
};