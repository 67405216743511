import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'temperatureLevel',
  initialState: {
    ok: true
  },
  reducers: {
    fetchTemperatureLevelRequest(state){
      state.loading = true;
      state.error = undefined;
    },
    fetchTemperatureLevelSuccess(state, action){
      state.ok = action.payload;
      state.loading = false;
    },
    fetchTemperatureLevelFailed(state, action){
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export { reducer, actions };