import { wrapClientApiCall } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall';

export const loadData = ({
  locale,
  setTaskInstances,
  createAlert,
  setLoading
}) => {

  const caller = wrapClientApiCall({
    url: 'taskinstances/fetch',
  })

  return caller({
    params: {
      application: 'distributions',
      context: locale, 
      date: new Date()
    }
  })
    .then(({ items }) => setTaskInstances(items))
    .catch(err => createAlert({ message: err.message }))
    .finally(() => setLoading(false));
};