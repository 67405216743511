import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const markBacsDelivered = ({
    createAlert,
    loadPlaces,
    orders,
    setOrders
}) => ({ order, order_id }) => (couloir) => {



    const savedOrders = orders;

    const orderBacs = order.bacs.filter(bac => bac.couloir === couloir);

    const deliveredBacs = orderBacs.every(bac => bac.deliveredAt);

    if (deliveredBacs) {
        loadPlaces();
        return;
    }

    const caller = wrapClientApiCall({
        url: 'orders/mark-bacs-delivered',
    });

    const formattedBacs = orderBacs.map(bac => ({ _id: bac._id, waiting: bac.waiting }))

    return caller({ params: { bacs: formattedBacs } })
        .then(({ bacs }) => {
            const ind = savedOrders.findIndex(order => order._id === order_id);
            savedOrders[ind].bacs = savedOrders[ind].bacs.map(b => {
                let bac = bacs.find((i) => i._id === b._id);
                if (bac) {
                    return bac;
                }
                return b;
            });
            setOrders(savedOrders);
        })
        .catch((err) => {
            createAlert(err)
        }).finally(loadPlaces());
};