import { BoutiqueOrder } from '../../../../models/BoutiqueOrder';

export const formatOrder = (order) => {

    let formattedOrder = new BoutiqueOrder(order);

    formattedOrder.items.forEach(item => {
        let formattedItem = item;
        let orderproducerproducts = order.producerproducts.find(opp => opp._id === item.producerproduct_id);
        formattedItem.producer_name = orderproducerproducts.producer_name;
        formattedItem.unit_display = orderproducerproducts.unit_display;
        formattedItem.product_name = orderproducerproducts.product_name;
        formattedItem.kelbongoo_price = orderproducerproducts.kelbongoo_price;
        formattedItem.full_value = item.consumer_price
    });

    formattedOrder.reduced.forEach(item => {
        let formattedItem = item;
        let orderproducerproducts = order.producerproducts.find(opp => opp._id === item.producerproduct_id);
        formattedItem.producer_name = orderproducerproducts.producer_name;
        formattedItem.unit_display = orderproducerproducts.unit_display;
        formattedItem.product_name = orderproducerproducts.product_name;
        formattedItem.kelbongoo_price = orderproducerproducts.kelbongoo_price;
        formattedItem.full_value = item.consumer_price
        formattedItem.reduced_value = item.consumer_price * (100 - item.percentage) / 100;
        
    });

    return formattedOrder;
};