// @ts-check
import { useState, useEffect, FC } from "react";
// @ts-ignore
import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export interface OrderDetails {
  status: number;
  numberbacs_frais: [];
  numberbacs_sec: [];
  bacs_frais: number[];
  bacs_sec: number[];
  numbernr_bacs?: [];
  createdAt: string;
  late_pickup: boolean;
  expired: boolean;
  first_order: boolean;
  _id: string;
  consumer_id: string;
  distribution_date: string;
  added_charges: any;
  objectitems: [];
  items: {
    producerproduct_id: string;
    quantity: number;
    consumer_price: number;
    tva: number;
  };

  globalorder_id: string;
  locale: string;
  anyreduced?: [];
  consumer_name: string;
  consumer_email: string;
  consumer_segmentation: number;
  orderbacs: any;
  bacs: any;
  id: string;
  consumer_telephone: string;
  consumer_address_city: string;
  consumer_address_street: string;
  consumer_address_zip: string;
  consumer_address_code: string;
  payments: any;
  // TODO: Extract removed type?
  objectremoved: [];
  removed: {
    reason: number;
    producerproduct_id: string;
    quantity: number;
    consumer_price: number;
    tva: number;
  };
  // TODO: Extract producerproducts type?
  objectproducerproducts: [];
  producerproducts: {
    _id: string;
    kelbongoo_price: number;
    consumer_price: number;
    producer_name: string;
    product_name: string;
    unit_display: string;
  };
}

/**
 * Display a badge for one bac.
 */
const BacBadge: FC<{ type: "frais" | "sec"; bac: number }> = ({
  type,
  bac,
}) => (
  <span
    className={`mr-2 badge badge-${type === "frais" ? "primary" : "success"}`}
    key={bac}
  >
    {bac}
  </span>
);

/**
 * Render the order bacs badge for an order.
 *
 * // FIXME: It does the fetching for now. Ideally it would only receive a struct with the two arrays.
 */
export const OrderBacs: FC<{ orderId: string }> = ({ orderId }) => {
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);

  useEffect(() => {
    // FIXME: This request should be done higher up in the component hierarchy. Ideally in a redux thunk.
    console.log(orderId);
    wrapClientApiCall({
      url: "orders/fetch-detail",
    })({
      params: {
        order_id: orderId,
        getOrderBacs: true,
      },
    }).then((orderDetails: { order: OrderDetails }) => {
      setOrderDetails(orderDetails.order);
      console.log(orderDetails);
    });
  }, [orderId]);

  if (!orderDetails) return null;

  return (
    <>
      {orderDetails?.bacs_frais?.map((bac) => (
        <BacBadge type="frais" bac={bac}></BacBadge>
      ))}
      {orderDetails?.bacs_sec?.map((bac) => (
        <BacBadge type="sec" bac={bac}></BacBadge>
      ))}
    </>
  );
};
