import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBackward,
  faCarrot,
  faCashRegister,
  faCheck,
  faCheckCircle,
  faCog,
  faComment,
  faComments,
  faDolly,
  faEuroSign,
  faExchangeAlt,
  faHome,
  faList,
  faSignInAlt,
  faSignOutAlt,
  faSnowflake,
  faTasks,
  faTimes,
  faUser,
  faMinus,
  faThumbsUp,
  faPeopleCarry,
  faExclamationCircle,
  faExclamationTriangle,
  faStar,
  faEject,
  faThermometerHalf,
  faSmileBeam,
  faBan,
  faTemperatureLow,
  faTemperatureHigh,
  faSync,
  faTimesCircle,
  faRedo,
  faCartPlus,
  faShoppingCart,
  faSquare,
  faCheckSquare,
  faPlus,
  faClock,
  faStore,
  faCaretRight,
  faReceipt,
  faQuestionCircle,
  faPauseCircle,
  faStopCircle,
  faPlayCircle
} from '@fortawesome/free-solid-svg-icons';

import {
  faClock as farClock,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBackward,
  faCarrot,
  faCashRegister,
  faCheck,
  faCheckCircle,
  faClock,
  faCog,
  faComment,
  faComments,
  faDolly,
  faEuroSign,
  faExchangeAlt,
  faHome,
  faList,
  faMinus,
  faPeopleCarry,
  faSignInAlt,
  faSignOutAlt,
  faSnowflake,
  faTasks,
  faThumbsUp,
  faTimes,
  faUser,
  faExclamationCircle,
  faExclamationTriangle,
  faStar,
  faEject,
  faThermometerHalf,
  faSmileBeam,
  faBan,
  faTemperatureHigh,
  faTemperatureLow,
  faSync,
  faTimesCircle,
  faRedo,
  faShoppingCart,
  faCartPlus,
  faSquare,
  faCheckSquare,
  faPlus,
  farClock,
  faStore,
  faCaretRight,
  faQuestionCircle,
  faReceipt,
  faPauseCircle,
  faStopCircle,
  faPlayCircle,
);