import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import * as Sentry from "@sentry/react";
import storage from 'redux-persist/lib/storage'

import { currentUserReducer, alertsReducer, actions, currentUserTransform } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { reducer as sessionReducer } from './reducers/session';
import { reducer as localesReducer } from './reducers/locales';
import { reducer as goppsReducer } from './reducers/gopps';
import { reducer as globalorderlocalesReducer } from './reducers/globalorderlocales';
import { reducer as productInfosReducer } from './reducers/productinfos';
import { reducer as orderCountsReducer } from './reducers/ordercounts';
import { reducer as tasksReducer } from './reducers/tasks';
import { reducer as temperatureRecordersReducer } from './reducers/temperatureRecorders';
import { reducer as temperatureLevelReducer } from './reducers/temperatureLevel';
import { reducer as boutiquedayReducer } from './reducers/boutiqueday';
import { reducer as placesReducer } from './reducers/places';


const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['currentUser', 'gopps', 'globalorderlocales', 'locales'],
  transforms: [currentUserTransform]
};

const appReducer = combineReducers({
  currentUser: currentUserReducer,
  alerts: alertsReducer,
  session: sessionReducer,
  locales: localesReducer,
  gopps: goppsReducer,
  globalorderlocales: globalorderlocalesReducer,
  productinfos: productInfosReducer,
  ordercounts: orderCountsReducer,
  tasks: tasksReducer,
  temperatureRecorders: temperatureRecordersReducer,
  temperatureLevel: temperatureLevelReducer,
  boutiqueday: boutiquedayReducer,
  places: placesReducer

});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'currentUser/logoutRequest') {
    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
  enhancers: [sentryReduxEnhancer]
});

const persistor = persistStore(store);

// try to reinflate loggedIn user on app reload...
store.dispatch(actions.verifyAuth());

export {
  persistor,
  store
}
