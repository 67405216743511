import { getRoleFromPath } from './getRoleFromPath';


export const getDropdownButtonVariant = path => {

  const role = getRoleFromPath(path);

  if(role === 'accueil') return 'primary';
  if(role === 'caisse') return 'primary';
  if(role === 'couloir') return 'primary';
  if(role === 'table') return 'primary';
  if(role === 'orders-nr') return 'primary';
  if(role === 'tasks') return 'primary';
  if(role === 'temperatures') return 'primary';
  return;
}