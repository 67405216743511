import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const fetchConsumerDetails = ({
    createAlert
}) => (consumerId) => {

    const caller = wrapClientApiCall({
        url: 'consumers/fetch-detail',
    });

    const params = {
        consumer_id: consumerId,
        userId: '2W8X2SaMhytesy4ne', // WARN
    };

    return caller({ params })
        .then((item) => item)
        .catch(err => createAlert(err));
};