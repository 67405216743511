import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'temperatureRecorders',
  initialState: {
    list: []
  },
  reducers: {
    fetchTemperatureRecordersRequest(state){
      state.loading = true;
      state.error = undefined;
    },
    fetchTemperatureRecordersSuccess(state, action){
      state.list = action.payload;
      state.loading = false;
    },
    fetchTemperatureRecordersFailed(state, action){
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export { reducer, actions };