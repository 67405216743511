import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { getAvailablePlaces } from "../helpers";

export const refreshQueue = ({
  globalorder_id,
  locale,
  localeDoc,
  activePlaces,
  createAlert,
  setQueue,
  setOrderTables,
  setAvailablePlaces
}) => {

  const options = {
    fields: {
      email: 1,
      consumer_name: 1,
      place: 1,
      table: 1,
      tabledAt: 1,
      first_order: 1
    }
  }

  const params = Object.assign(
    {
      globalorder_id,
      locale,
      arrivedAt: { $exists: true },
      tableDepartedAt: { $exists: false },
      addPlaces: true
    }
  );

  const caller = wrapClientApiCall({
    url: 'orders/fetch'
  })

  return caller({
    params,
    options
  })
    .then(({ items }) => {
      
      setAvailablePlaces(getAvailablePlaces(localeDoc, activePlaces, items))

      const orderTables = [];
      const queue = { orders: [] };
      const tables = localeDoc.tables.filter(table => table.active)

      for (let order of items) {
        if (typeof order.place !== 'undefined') {
          if (typeof orderTables[order.table] === 'undefined') {
            orderTables[order.table] = {
              orders: [],
              alias: tables[order.table].name
            }
          }
          orderTables[order.table].orders.push(
            Object.assign({}, order, { place: order.place + 1 })
          );
        }
        else {
          queue.orders.push(order)
        }
      }
      setOrderTables(orderTables);
      setQueue(queue)
    })
    .catch(error => {
      createAlert({ message: error.message })
      return error;
    });

}