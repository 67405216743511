import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Datatable } from '@kelbongoo/shared-client-react-v2/dist/components/Datatable';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import selectors from '../../../selectors';
import { refreshItems } from './actions';
import { tableColumns, buildFilters } from './helpers';
import { OrdersRow } from './OrdersRow';

import { useInterval } from '@kelbongoo/shared-client-react-v2/dist/utils/CustomHooks/useInterval'
const DEFAULT_REFRESH_PERIOD_MS = 60000;

export const OrdersBase = () => {

  const dispatch = useDispatch();

  const { currentLocale: locale } = useSelector(state => state.locales);
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const { init } = useSelector(state => state.session);
  const { gopps } = useSelector(state => state.gopps);
  const producers = selectors.getAllProducers(gopps);

  const {
    searchFilter,
    quickFilter
  } = buildFilters({ producers });

  const defaultQuery = quickFilter.valueMapping(quickFilter.defaultValue)

  const [items, setItems] = useState([]);
  const [count, setCount] = useState([]);
  const [page, setPage] = useState(1);
  const [docsPerPage, setDocsPerPage] = useState(10);
  const [currentQuery, setCurrentQuery] = useState(defaultQuery);
  const [loading, setLoading] = useState(true);

  const createAlert = arg => dispatch(createAlertBase(arg))

  const updateQuery = update => {
    setCurrentQuery({
      ...currentQuery,
      ...(update || {})
    })
    setPage(1)
  }

  const loadData = () => {
    refreshItems({
      createAlert,
      currentQuery,
      docsPerPage,
      globalorder_id,
      gopps,
      locale,
      page,
      setCount,
      setItems,
      setLoading,
    })
  }

  useEffect(loadData, [currentQuery, page, globalorder_id, gopps, locale, docsPerPage, init])
  useInterval(loadData, DEFAULT_REFRESH_PERIOD_MS)
  useEffect(() => {
    setCurrentQuery(defaultQuery)
  }, [])

  return (
    <Datatable
      columns={tableColumns}
      count={count}
      currentPage={page}
      DatatableRow={OrdersRow}
      docsPerPage={docsPerPage}
      items={items}
      loading={loading || init}
      quickFilter={quickFilter}
      searchFilter={searchFilter}
      showPagination={true}
      tableName={`accueil`}
      updateDocsPerPage={setDocsPerPage}
      updatePage={setPage}
      updateQuery={updateQuery}
      useSelector={useSelector}
      dispatch={dispatch}
    />
  )
}

export const Orders = () => (
  <ErrorBoundary>
    <EnsureGlobalOrderWrapper>
      <OrdersBase />
    </EnsureGlobalOrderWrapper>
  </ErrorBoundary>
)