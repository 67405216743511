import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

export const refreshData = ({
    createAlert,
    locale,
    localeDocument,
    globalorder_id,
    setBacs,
    getBacPlaceParams,
    couloir,
    maxBacs,
    setLoading
}) => {

    setLoading(true);

    const caller = wrapClientApiCall({
        url: 'globalorderlocales/get-distribution-couloir',
    })

    return caller({
        params: {
            locale: locale,
            globalorder_id: globalorder_id,
            couloir: couloir,
            maxBacs: maxBacs
        },
    })
        .then(({ places, bacs }) => {
            setBacs(
                bacs.map(bac => Object.assign(
                    getBacPlaceParams(localeDocument, places, bac),
                    bac
                ))
            );
            setLoading(false);
        })
        .catch((err) => {
            createAlert(err)
            setLoading(false);
        });
};