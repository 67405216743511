import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import { CaisseHeader } from './CaisseHeader';
import { Orders } from '../Orders';
import { BoutiqueOrders } from '../BoutiqueOrders';
import { BoutiqueOrderNew } from '../BoutiqueOrderNew';

const TAB_SESSION_STORAGE_KEY = 'caisse_page'

export const CaisseBase = () => {

  const sessionPage = sessionStorage.getItem(TAB_SESSION_STORAGE_KEY)
  const [currentPage, setCurrentPage] = useState(sessionPage ? sessionPage : 'orders');

  const setCurrentPageFunc = (page) => {
    setCurrentPage(page);
    sessionStorage.setItem(TAB_SESSION_STORAGE_KEY, page);
  }

  return (
    <Container fluid>
      <CaisseHeader
        currentPage={currentPage}
        setCurrentPage={setCurrentPageFunc}
      />

      {currentPage === 'orders' ?
        <Orders />
        : currentPage === 'boutiqueorders' ?
          <BoutiqueOrders />
          : currentPage === 'boutiqueordersnew' ?
            <BoutiqueOrderNew />
            :
            <ErrorBoundary />
      }

    </Container>
  )
}

export const Caisse = () => (
  <ErrorBoundary>
    <EnsureGlobalOrderWrapper>
      <CaisseBase />
    </EnsureGlobalOrderWrapper>
  </ErrorBoundary>
)