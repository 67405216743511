import { useEffect, useState } from 'react';

import { Checkbox } from '@kelbongoo/shared-client-react-v2/dist/components/Checkbox';
import { TouchActivatedInventory } from '@kelbongoo/shared-client-react-v2/dist/components/TouchActivatedInventory';
import { ModalButton } from '@kelbongoo/shared-client-react-v2/dist/components/ModalButton';

import { ModalBody } from './ModalBody';

const getAbandonnedParams = (paid, refundable) => {
    if (refundable || !paid) {
        return {
            destination: 'B',
            label: 'primary'
        };
    }
    return {
        destination: 'X',
        label: 'danger'
    };
};

export const OrderNrDetailItem = ({
    consumer_price,
    producerproduct_id,
    product_name,
    producer_name,
    unit_display,
    quantity,
    expectedQuantity,
    updateQuantity,
    tva,
    reduced,
    abandonned,
    validated,
    paid,
    refundable,
    completed,
    onToggleChecked,
    percentage,
    updateReduction,
    disabled
}) => {


    const [completedState, setCompletedState ] = useState(completed)
    const [quantityState, setQuantityState ] = useState(quantity)
    const [expectedQuantityState, setExpectedQuantityState ] = useState(expectedQuantity)
    const [validatedState, setValidatedState ] = useState(validated)
    const [paidState, setPaidState ] = useState(paid)
    const [refundableState, setRefundableState ] = useState(refundable)
    const [percentageState, setPercentageState ] = useState(percentage)
    const [disabledState, setDisabledState ] = useState(disabled)
    const [reducedState, setReducedState ] = useState(reduced)

    useEffect(() => {
        setQuantityState(quantity)
        setExpectedQuantityState(expectedQuantity)
        setCompletedState(completed)
        setValidatedState(validated)
        setPaidState(paid)
        setRefundableState(refundable)
        setPercentageState(percentage)
        setDisabledState(disabled)
        setReducedState(reduced)
    }, [producerproduct_id])

    const inputProps = {
        onUpdate: q => {updateQuantity(q)},
        disabled: validatedState,
        value: quantityState,
        showExpected: true,
        expectedValue: expectedQuantityState,
        // timeout: 1000
    };

    const abandonnedParams = getAbandonnedParams(paidState, refundableState);

    const checkboxProps = {
        // inputGroupClasses: '',
        value: completedState,
        onToggleChecked: id => {setCompletedState(!completedState); onToggleChecked(id)}
    };

    const reduceModalParams = {
        buttonClasses: `btn-sm vertical-center ${reduced ? 'btn-info' : 'btn-default'}`,
        buttonText: reduced ? `${reduced} (${percentageState} %)` : '0',
        modalHeaderTitle: 'Remise sur un produit',
        buttonStyle: { borderRadius: '10%', lineHeight: '1' },
        buttonProps: { variant: 'primary' },
        // // modalProps: '',
        disabled: !!disabledState
    }

    return (
        <tr className={reduced && 'warning'}>
            <td>
                <div className='pull-left mr-1'>
                    <div>
                        <ModalButton {...reduceModalParams}>
                            <ModalBody
                                isReduced={reduced}
                                quantity={quantityState}
                                updateItem={updateReduction}
                            />
                        </ModalButton>
                    </div>
                    {abandonned &&
                        <div className='text-center'>
                            <span className={`label label-${abandonnedParams.label}`}>
                                {abandonnedParams.destination}
                            </span>
                        </div>
                    }
                </div>
                <div className='pull-left'>
                    <div>
                        <b>{product_name}</b>
                    </div>
                    <div className='italic font-sm'>{producer_name}</div>
                    <div className='italic font-sm text-muted'>{unit_display}</div>
                </div>
            </td>
            <td className='text-center vertical-center' style={{ width: '120px' }}>
                <TouchActivatedInventory {...inputProps} />
            </td>
            {!validated &&
                <td className='text-center vertical-center' style={{ width: '70px' }}>
                    <Checkbox {...checkboxProps} />
                </td>
            }
        </tr>
    )
}