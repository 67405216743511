import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { formatOrder } from '../helpers';

export const fetchDetail = ({
  order_id,
  setLoading,
  createAlert,
  setCurrentOrder,
  getBacDetail,
  setBacs
}) => {

  setLoading(true)

  const caller = wrapClientApiCall({
    url: 'orders/fetch-detail',
  });

  return caller({
    params: {
      order_id,
      getPayments: true,
      getBoutiqueOrder: true,
      getOrderBacs: true,
      getComments: true
    }
  })
    .then(({ order }) => {
      let futureBacs =
        order.bacs.map(async bac => {
          return getBacDetail({
            bac: bac.number,
            couloir: bac.couloir
          })
        }
        )
      return Promise.all(futureBacs).then(bacs => {
        let filteredBacs = bacs;

        filteredBacs.map(bac => {
          let filteredBac = bac;
          filteredBac.items = filteredBac.items.filter(item => order.items.map(i => i.producerproduct_id).includes(item.producerproduct_id));
        })
        setBacs(filteredBacs)
        let bacsproducerproducts = filteredBacs.map(bac => {
          return bac.items.map(item => {
              return ({
                producerproduct_id: item.producerproduct_id,
                couloir: bac.couloir,
                bac: bac.bac
              })
            })
        }).flat();
        setCurrentOrder(formatOrder(order, bacsproducerproducts));
      })
    })
    .catch(err => { createAlert(err) })
    .finally(
      setLoading(false)
    )
};