import React from 'react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store, persistor } from './store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import * as serviceWorker from './serviceWorker';

let REACT_APP_SENTRY_DSN;

// if(process.env.NODE_ENV === 'production'){
//   REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
// }

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  release: "distributions@" + process.env.npm_package_version,
  integrations: [new Integrations.BrowserTracing({
    tracingOrigins: ["distributions.kelbongoo.com", "distributions-new.kelbongoo.com"],
  })],
  tracesSampleRate: 0.1
});


const renderReactDom = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
