import getPaymentMethodTitle from '@kelbongoo/shared-methods/paymentMethods/getPaymentMethodTitle';
import { run as formatDate } from '@kelbongoo/shared-methods/utils/formatDate';
import { run as formatMoney } from '@kelbongoo/shared-methods/utils/formatMoney';

// // printReceipt = ({ items, reduced }) => {
//     if (Meteor.isCordova) {
//       const boutiqueorder = this.boutiqueorder.get();
//       const payment =
//         boutiqueorder &&
//         boutiqueorder.payments &&
//         boutiqueorder.payments.length > 0 &&
//         boutiqueorder.payments[0];

//       if (!payment) {
//         return;
//       }

//       const boutique = this.currentBoutiqueDocument();
//       const locale = this.locale.get();
//       const payment_type = getPaymentMethodTitle(payment.method);
//       const allItems = [...(items || []), ...(reduced || [])];

//       plugin.PosPrinter.printFormattedText(
//         [
//           {
//             boutique: {
//               name: boutique.name,
//               address_street: locale.address.street,
//               address_zip: locale.address.zip,
//               address_city: locale.address.city,
//             },
//             date: formatDate.run(new Date(), 'dddd DD MMMM HH:mm'),
//             order_id: boutiqueorder._id,
//             payment_type,
//             items: allItems.map((item) => {
//               let product_name =
//                 item.product_name.length > 25
//                   ? `${item.product_name.substring(0, 10)}...`
//                   : item.product_name;

//               return {
//                 product_name,
//                 quantity: item.quantity,
//                 consumer_price: formatMoney.run(item.reduced_unit_price),
//                 total_price: formatMoney.run(item.reduced_value),
//               };
//             }),
//             amount_total: formatMoney.run(boutiqueorder.getAmountTotal()),
//             amount_total_ht: formatMoney.run(boutiqueorder.getAmountTotalHT()),
//             amount_tva: formatMoney.run(boutiqueorder.getAmountTotalTVA()),
//           },
//         ],
//         (result) => handleResponse(result),
//         (error) => handleResponse(error)
//       );
//     }
//   };
// });

export const printReceipt = ({
    items,
    reduced,
    isMobile,
    createAlert,
    currentOrder,
    localeDocument,
}) => {

    if (!isMobile) {
        return
    }

    const payment =
        currentOrder &&
        currentOrder.payments &&
        currentOrder.payments.length > 0 &&
        currentOrder.payments[0];

    if (!payment) {
        return;
    }

    const payment_type = getPaymentMethodTitle(payment.method);
    const allItems = [...(items || []), ...(reduced || [])];

    window.PosPrinter.printFormattedText(
        [
            {
                boutique: {
                    name: localeDocument.name,
                    address_street: localeDocument.address.street,
                    address_zip: localeDocument.address.zip,
                    address_city: localeDocument.address.city,
                },
                date: formatDate.run(new Date(), 'dddd DD MMMM HH:mm'),
                order_id: currentOrder._id,
                payment_type,
                items: allItems.map((item) => {
                    let product_name =
                        item.producer_name.length > 25
                            ? `${item.product_name.substring(0, 10)}...`
                            : item.product_name;

                    return {
                        product_name,
                        quantity: item.quantity,
                        consumer_price: formatMoney.run(item.consumer_price),
                        total_price: formatMoney.run(item.consumer_price * item.quantity),
                    };
                }),
                amount_total: formatMoney.run(currentOrder.getAmountTotal()),
                amount_total_ht: formatMoney.run(currentOrder.getAmountTotalHT()),
                amount_tva: formatMoney.run(currentOrder.getAmountTotalTVA()),
            },
        ],
        () => { }, // successStatement
        (error) => createAlert(error)
    );

}