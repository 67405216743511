import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createAlert as createAlertBase } from '@kelbongoo/shared-client-react-v2/dist/components/SharedBase';
import { EnsureGlobalOrderWrapper } from '@kelbongoo/shared-client-react-v2/dist/components/SharedPrepaDistribBase';
import { ErrorBoundary } from '../../ui/ErrorBoundary';
import selectors from '../../../selectors';
import { refreshData, toggleBacPickedUp, markBacsDelivered, refreshBacs } from './actions';
import { LoadingSpinner } from '@kelbongoo/shared-client-react-v2/dist/components/LoadingSpinner';
import { ValidationButton } from '@kelbongoo/shared-client-react-v2/dist/components/ValidationButton';
import { CouloirHeader } from './CouloirHeader';
import { CouloirItem } from './CouloirItem';

import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';

import { useInterval } from '@kelbongoo/shared-client-react-v2/dist/utils/CustomHooks/useInterval'
const DEFAULT_REFRESH_PERIOD_MS = 5000;

const DEFAULT_MAX_BACS = 8;
const DEFAULT_COULOIR = 'frais';

const getBacPlaceParams = (locale, places, bac) => {
  let place = places.find(p => p.order_id === bac.order_id);
  let table = place && locale.tables.find((t, index) => index === place.table);
  return table ? {
    place: place.place,
    table: place.table,
    tableName: table.name,
    placeName: place.alias
  } : {};
};

const formatBacPickedUp = (bacs, bac_id, value) => {
  let ind = bacs.findIndex(
    bac => bac._id === bac_id
  );
  bacs[ind].pickedUpAt = value === 'auto'
    ? !bacs[ind].pickedUpAt
    : value;
  return bacs;
};

export const CouloirBase = () => {

  const dispatch = useDispatch();
  const [currentCouloir, setCurrentCouloir] = useState(DEFAULT_COULOIR);
  const [currentMaxBacs, setCurrentMaxBacs] = useState(DEFAULT_MAX_BACS);
  const [showDeliveryTable, setShowDeliveryTable] = useState(true);
  const [bacs, setBacs] = useState([]);
  const [loading, setLoading] = useState(true);

  const { currentLocale: locale } = useSelector(state => state.locales);
  const { currentGlobalOrder: globalorder_id } = useSelector(state => state.globalorderlocales);
  const { init } = useSelector(state => state.session);
  const localeDocument = selectors.getCurrentLocaleDocument();

  

  const createAlert = arg => dispatch(createAlertBase(arg))

  const loadData = () => {
    refreshData({
      couloir: currentCouloir,
      maxBacs: currentMaxBacs,
      createAlert,
      locale,
      localeDocument,
      globalorder_id,
      setBacs,
      getBacPlaceParams,
      setLoading,
    })
  }

  const loadBacs = () => {
    refreshBacs({
      couloir: currentCouloir,
      maxBacs: currentMaxBacs,
      createAlert,
      locale,
      localeDocument,
      globalorder_id,
      setBacs,
      getBacPlaceParams,
    })

  }

  const handleBacClick = (bac_id) => () => toggleBacPickedUp({
    createAlert,
    bac_id,
    setBacs,
    bacs,
    formatBacPickedUp,
    loadBacs
  })

  const handleValidationClick = () => markBacsDelivered({
    createAlert,
    bacs,
    setBacs,
    loadBacs
  })

  useEffect(loadData, [, init, globalorder_id, locale])
  useEffect(loadBacs, [currentCouloir, currentMaxBacs])

  useInterval(loadBacs, DEFAULT_REFRESH_PERIOD_MS)

  return (
    <>
      {loading ?
        <LoadingSpinner></LoadingSpinner>
        :
        <>
          <CouloirHeader
            currentCouloir={currentCouloir}
            currentMaxBacs={currentMaxBacs}
            showDeliveryTable={showDeliveryTable}
            setCurrentCouloir={setCurrentCouloir}
            setCurrentMaxBacs={setCurrentMaxBacs}
            setShowDeliveryTable={setShowDeliveryTable}
            handleRefreshClick={loadBacs}
          />


          {bacs && bacs.length > 0 ?
            <ListGroup className='position-relative'>
              {bacs.map(bac => <CouloirItem
                bac={bac}
                couloir={currentCouloir}
                handleBacClick={handleBacClick(bac._id)}
                showDeliveryTable={showDeliveryTable}
              />)}
            </ListGroup>
            :
            <Container fluid><h1>Aucun bac en attente</h1></Container>
          }

          {showDeliveryTable ? <ValidationButton
            buttonText='Valider la livraison'
            buttonClasses='btn-block btn-lg mt-3'
            enabled={bacs && bacs.length > 0}
            // buttonProps={}
            onValidation={handleValidationClick}
            mustConfirm={false}
            // confirmationText='Les bacs sélectionnés ont-ils été livrés aux tables ?'
          /> : ''}

        </>
      }
    </>
  )
}

export const Couloir = props => (
  <ErrorBoundary>
    <EnsureGlobalOrderWrapper>
      <CouloirBase {...props} />
    </EnsureGlobalOrderWrapper>
  </ErrorBoundary>
)