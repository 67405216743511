import { Order } from '../../../../models/Order';

const alphabeticalSort = (a, b) => {
    if (a.product_name < b.product_name) return -1;
    if (a.product_name > b.product_name) return 1;
    return 0;
}

export const formatOrder = (order, bacs = []) => {
    let formattedOrder = new Order(order);

    if (formattedOrder.items) {
        formattedOrder.items.forEach(item => {
            let formattedItem = item;
            let orderproducerproducts = order.producerproducts.find(opp => opp._id === item.producerproduct_id);
            let bacsproducerproduct = bacs.find(bac => bac.producerproduct_id === item.producerproduct_id);
            formattedItem.producer_name = orderproducerproducts.producer_name;
            formattedItem.unit_display = orderproducerproducts.unit_display;
            formattedItem.product_name = orderproducerproducts.product_name;
            formattedItem.kelbongoo_price = orderproducerproducts.kelbongoo_price;
            formattedItem.bac = bacsproducerproduct && bacsproducerproduct.bac;
            formattedItem.couloir = bacsproducerproduct ? bacsproducerproduct.couloir : 2; // fake coulopir when none exists for sorting reasons
        })

        formattedOrder.items.sort(alphabeticalSort);

        formattedOrder.items.sort((a, b) => {
            if (a.couloir < b.couloir) return -1;
            if (a.couloir > b.couloir) return 1;
            return 0;
        });
    }

    if (formattedOrder.removed) {

        formattedOrder.removed.forEach(item => {
            let formattedItem = item;
            let orderproducerproducts = order.producerproducts.find(opp => opp._id === item.producerproduct_id);
            formattedItem.producer_name = orderproducerproducts.producer_name;
            formattedItem.unit_display = orderproducerproducts.unit_display;
            formattedItem.product_name = orderproducerproducts.product_name;
            formattedItem.kelbongoo_price = orderproducerproducts.kelbongoo_price;
        })

        formattedOrder.removed.sort(alphabeticalSort);
    }
    
    if (formattedOrder.reduced) {

        formattedOrder.reduced.forEach(item => {
            let formattedItem = item;
            let orderproducerproducts = order.producerproducts.find(opp => opp._id === item.producerproduct_id);
            formattedItem.producer_name = orderproducerproducts.producer_name;
            formattedItem.unit_display = orderproducerproducts.unit_display;
            formattedItem.product_name = orderproducerproducts.product_name;
            formattedItem.kelbongoo_price = orderproducerproducts.kelbongoo_price;
        })


        formattedOrder.reduced.sort(alphabeticalSort);
    }

    if (formattedOrder.added) {

        formattedOrder.added.forEach(item => {
            let formattedItem = item;
            let orderproducerproducts = order.producerproducts.find(opp => opp._id === item.producerproduct_id);
            formattedItem.producer_name = orderproducerproducts.producer_name;
            formattedItem.unit_display = orderproducerproducts.unit_display;
            formattedItem.product_name = orderproducerproducts.product_name;
            formattedItem.kelbongoo_price = orderproducerproducts.kelbongoo_price;
        })


        formattedOrder.added.sort(alphabeticalSort);
    }

    if (formattedOrder.added_reduced) {

        formattedOrder.added_reduced.forEach(item => {
            let formattedItem = item;
            let orderproducerproducts = order.producerproducts.find(opp => opp._id === item.producerproduct_id);
            formattedItem.producer_name = orderproducerproducts.producer_name;
            formattedItem.unit_display = orderproducerproducts.unit_display;
            formattedItem.product_name = orderproducerproducts.product_name;
            formattedItem.kelbongoo_price = orderproducerproducts.kelbongoo_price;
        })

        formattedOrder.added_reduced.sort(alphabeticalSort);
    }
    return formattedOrder;
};