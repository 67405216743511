import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";

const fields = {
  createdAt: 1,
  paidAt: 1,
  items: 1,
  reduced: 1,
  payments: 1,
  consumer_id: 1,
};

const formatItems = ({ items, gopps }) => {
  return items.map(item => {
    return {
      ...item,
      gopps
    };
  })
};

export const boutiqueordersFetch = ({
  page,
  locale,
  boutiqueday_id,
  currentQuery = {},
  setBoutiqueorders,
  setCount,
  setLoading,
  gopps = [],
  createAlert,
  docsPerPage,
}) => {

  const options = {
    page,
    fields,
    limit: docsPerPage,
    sort: { paidAt: 1, name: 1 },
  };

  const params = Object.assign(
    {
      boutique: locale,
      boutiqueday_id: boutiqueday_id,
    },
    currentQuery || {}
  );

  const caller = wrapClientApiCall({
    url: 'boutiqueorders/fetch'
  })

  setLoading(true);

  return caller({
    params,
    options,
  })
    .then(({ items, count }) => {
      let formattedItems = formatItems({ items, gopps })
      setBoutiqueorders(formattedItems);
      setCount(count);
      setLoading(false);
    })
    .catch(error => {

      createAlert({ message: error.message })
      setLoading(false);
      return error;
    });

}