import { wrapClientApiCall } from "@kelbongoo/shared-client-react-v2/dist/components/SharedBase/wrapClientApiCall";
import { actions } from '../reducers/places';

export const loadPlaces = () => (dispatch, getState) => {

    const { locales: { currentLocale: locale } } = getState();

    const {
        fetchPlacesFailed,
        fetchPlacesRequest,
        fetchPlacesSuccess
    } = actions;


    const caller = wrapClientApiCall({
        url: 'locales/fetch-places',
    })

    dispatch(fetchPlacesRequest())

    return caller({
        params: {
            code: locale,
        },
    })
        .then(({ places }) => {
            dispatch(fetchPlacesSuccess(places));
        })
        .catch((err) => {
            dispatch(fetchPlacesFailed(err));
        });
};