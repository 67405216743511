import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Dropdown from "react-bootstrap/Dropdown";

import { Icon } from "@kelbongoo/shared-client-react-v2/dist/components/Icon";

import styles from "./TableHeader.module.css";

export const FontSizeManager = ({ ...props }) => {
  const [fontSize, setFontSize] = useState(() => 16);

  useEffect(() => {
    const sizeWithPx = localStorage.getItem("TABLE_FONT_SIZE");
    const size = sizeWithPx?.replace("px", "");
    console.log(size);
    let parsedSize;
    if (size && !isNaN(parseInt(size))) {
      parsedSize = parseInt(size);
    } else {
      parsedSize = 16;
    }
    document.documentElement.style.setProperty(
      "--table-font-size",
      `${parsedSize}px`
    );
  }, [setFontSize]);

  const changeSize = (size) => {
    const fontSizePx = `${size}px`;
    setFontSize(size);
    localStorage.setItem("TABLE_FONT_SIZE", fontSize);
    document.documentElement.style.setProperty("--table-font-size", fontSizePx);
  };

  return (
    <ButtonGroup {...props}>
      <Button variant="outline-primary" size="lg" onClick={() => changeSize(fontSize + 1)}>
        +
      </Button>
      <Button variant="outline-primary" size="lg" onClick={() => changeSize(fontSize - 1)}>
        -
      </Button>
    </ButtonGroup>
  );
};

export const TableHeader = ({
  handleRefreshClick,
  tables,
  currentTable,
  setCurrentTable,
  showAccueilNd,
  setShowAccueilNd,
  showLibreServiceNd,
  setShowLibreServiceNd,
  showFraisNd,
  setShowFraisNd,
  showSecNd,
  setShowSecNd,
}) => {
  const handleCurrentTableClick = (table) => () => setCurrentTable(table);
  const handleShowAccueilNdClick = (bool) => () => setShowAccueilNd(bool);
  const handleShowLibreServiceNdClick = (bool) => () =>
    setShowLibreServiceNd(bool);
  const handleShowFraisNdClick = (bool) => () => setShowFraisNd(bool);
  const handleShowSecNdClick = (bool) => () => setShowSecNd(bool);
  const [showOptions, setShowOptions] = useState(false);

  return (
    <>
      <header className={styles.header}>
        <h5 className="text-capitalize m-0">Rappel bacs</h5>
        <ButtonToolbar style={{ gap: "8px" }}>
          <FontSizeManager className="mr-2" />
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => setShowOptions(!showOptions)}
          >
            Options {showOptions ? "↑" : "↓"}
          </Button>
          <Button variant="secondary" onClick={handleRefreshClick}>
            <Icon name="sync" />
          </Button>
        </ButtonToolbar>
      </header>
      {showOptions && (
        <ButtonToolbar
          className="mb-2"
          aria-label="Configuration interface couloir"
        >
          <ButtonGroup
            style={{ marginLeft: "auto" }}
            size="sm"
            className="mr-2"
            aria-label="Actualisation"
          ></ButtonGroup>
          {tables.length > 1 ? (
            <Dropdown
              as={ButtonGroup}
              id={`dropdown-maxbacs`}
              size="sm"
              title="Choix table"
              drop="right"
              className="mr-2"
            >
              <Dropdown.Toggle variant="secondary">
                Changer de table
              </Dropdown.Toggle>
              <Dropdown.Menu className="text-capitalize">
                {tables.map((table) => (
                  <Dropdown.Item
                    onClick={handleCurrentTableClick(table.number)}
                    key={table.number}
                  >
                    Table
                    {/* Table {table.name} */}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
          <ButtonGroup size="sm" className="mr-2">
            <Button
              variant="outline-warning"
              size="sm"
              onClick={handleShowAccueilNdClick(!showAccueilNd)}
            >
              <Icon
                name={showAccueilNd ? "check-square" : "square"}
                textRight
              />
              Accueil
            </Button>
            <Button
              variant="outline-danger"
              size="sm"
              onClick={handleShowLibreServiceNdClick(!showLibreServiceNd)}
            >
              <Icon
                name={showLibreServiceNd ? "check-square" : "square"}
                textRight
              />
              Libre-service
            </Button>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={handleShowFraisNdClick(!showFraisNd)}
            >
              <Icon name={showFraisNd ? "check-square" : "square"} textRight />
              Frais
            </Button>
            <Button
              variant="outline-success"
              size="sm"
              onClick={handleShowSecNdClick(!showSecNd)}
            >
              <Icon name={showSecNd ? "check-square" : "square"} textRight />
              Sec
            </Button>
          </ButtonGroup>
          <Dropdown
            as={ButtonGroup}
            id={`dropdown-maxbacs`}
            size="sm"
            title="Choix table"
            drop="right"
            className="mr-2"
          ></Dropdown>
        </ButtonToolbar>
      )}
    </>
  );
};
