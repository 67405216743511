export const buildFilters = () => {

  const searchFilter = {
    keys: ['consumer_name'],
    placeholder: 'Chercher par nom',
  };

  const quickFilter = {
    keys: ['paidAt','arrivedAt'],
    items: [
      { value: 'all', name: 'Toutes les commandes' },
      { value: 'paid', name: 'Réglées' },
      { value: 'not-paid', name: 'Arrivées et non réglées' },
      { value: 'arrived', name: 'Arrivées' }
    ],
    defaultValue: 'not-paid',
    valueMapping(value){
      if(value === 'all') return {paidAt: undefined, arrivedAt: undefined};
      if(value === 'paid') return { paidAt: { $exists: true }, arrivedAt: undefined};
      if(value === 'arrived') return { paidAt: undefined, arrivedAt: { $exists: true }};
      if(value === 'not-paid') return { 
        paidAt: null, 
        arrivedAt: { $exists: true }
      };
    }
  }

  return {
    searchFilter,
    quickFilter
  }
}